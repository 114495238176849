import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  Checkbox,
  Container,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import AuthLayout from "../../Layouts/AuthLayout";
import ImgWithPopup from "../../Components/ImgWithPopup";
import SiteLogo from "../../Components/Logo";
import SignupAvatar from "../../Assets/Images/signupimg.png";
import RegisterWithGoogle from "../../Components/RegisterWithGoogle";
import Password from "../../Components/Password";
import CheckboxLabel from "../../Components/Checkbox";
import Button from "../../Components/Button";
import { useTheme } from "@emotion/react";
import CustomField from "../../Components/CustomField";
import { useState } from "react";
import TermsAndConditions from "./TermsAndConditions";
import PhoneNumber from "../../Components/PhoneNumber";
import AccountVarification from "./AccountVarification";
import { json, Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SelectLanguage from "../../Components/LanguageSelect";
import LoginWithGoogle from "../../Components/RegisterWithGoogle";
import CircularProgress from "@mui/material/CircularProgress";
import Cookie from "js-cookie";
import { updateUser } from "../../Redux/Slices/userSlice";
import { useDispatch, useSelector } from "react-redux";
import RegisterWithMicrosoft from "./RegisterWithMicrosoft";
import toast, { Toaster } from "react-hot-toast";
import Yahoo from "./Yahoo";
const Item = styled(Paper)(({ theme }) => ({
  borderRadius: "19px",
  padding: theme.spacing(1),
}));
const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

export default function SignUp() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [pno, setPno] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [passwordValue, setPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [errors, seterrors] = useState(false);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [showAccountVarificationModal, setShowAccountVarificationModal] =
    useState(false);

  const TermsCondition = () => {
    setShowModal(true);
  };

  const AccountVarifications = () => {
    setShowAccountVarificationModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (passwordValue !== confirmPasswordValue) {
      console.error("Passwords do not match");
      setPasswordsMatch(false); // Update state to indicate passwords don't match
      return;
    }
    setPasswordsMatch(true);
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (!regex.test(passwordValue)) {
      console.error("Password doesn't meet the criteria.");
      return;
    }

    const textregex = /^(?!.*\d)[a-zA-Z]{3,}$/;
    if (!textregex.test(fname)) {
      console.error("text doesn't meet the criteria.");
      return;
    }

    const lnameregex = /^(?!.*\d)[a-zA-Z]{3,}$/;
    if (!lnameregex.test(lname)) {
      console.error("text doesn't meet the criteria.");
      return;
    }
    // const pnoregex =  /^\+?[0-9]{1,3}?[-.●]?([0-9]{3})[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
    // if (!pnoregex.test(pno)) {
    //   console.error("text doesn't meet the criteria.");
    //   return;
    // }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      console.error("text doesn't meet the criteria.");
      setLoading(false);
      return;
    }
    if (!agreeTerms) {
      console.error("Terms and Conditions not agreed."); // Log an error if terms are not agreed
      setTermsError(true); // Set terms error state to true
      setLoading(false);
      return;
    }
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    const formdata = new FormData();
    formdata.append("first_name", fname);
    formdata.append("last_name", lname);
    formdata.append("email", email);
    formdata.append("password", passwordValue);
    formdata.append("phone", pno.replace(/\D/g, ""));

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(`${apiEndpoint}/register`, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          return { error: { message: "Unauthorized" } };
        }
      })
      .then((result) => {
        setLoading(false);
        // console.log(result.data);
        if (result.error) {
          seterrors(result.error);
          // Navigate unathrized msg to 401 page
        } else {
          Cookie.set("access_token", result.data.token, { expires: 14 });
          Cookie.set("user_email", result.data.user.email, { expires: 14 });
          dispatch(
            updateUser({
              user: result.data,
            })
          );
          setFname("");
          setLname("");
          setEmail("");
          setPno("");
          setPasswordValue("");
          setConfirmPasswordValue("");
          setAgreeTerms(false);
          AccountVarifications();
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false); // Set loading to false on error
      });
    // AccountVarifications();
  };

  const handleCheckboxChange = (event) => {
    setAgreeTerms(event.target.checked); // Update state based on checkbox status
    setTermsError(false); // Reset terms error state when checkbox is checked
  };

  const location = useLocation();
  // const userinfo = useSelector((state) => state.authFlow?.userinfo);
  // const authFlowType = useSelector((state) => state.authFlow?.authFlowType);
  let userinfo = localStorage.getItem("userinfo");
  const authFlowType = localStorage.getItem("authFlowType");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (userinfo) {
      if (authFlowType && authFlowType == "register") {
        const user = JSON.parse(userinfo);
        const firstName = user.givenName;
        const lastName = user.surname;
        const email = user.mail;
        const password = user.id;
        setEmail(email);

        const myHeaders = new Headers();
        myHeaders.append(
          `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
          `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
        );
        const formdata = new FormData();
        formdata.append("first_name", firstName);
        formdata.append("last_name", lastName);
        formdata.append("email", email);
        formdata.append("password", password);
        formdata.append("isApple", false);

        const authFlowSource = localStorage.getItem("authFlowSource");
        if (authFlowSource && authFlowSource == "outlook") {
          formdata.append(
            "refresh_token",
            localStorage.getItem("refresh_token")
          );
          formdata.append("account_type", "outlook");
        }

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: formdata,
          redirect: "follow",
        };
        setLoading(true);
        fetch(`${apiEndpoint}/register`, requestOptions)
          .then((response) => {
            if (response.status === 200) {
              return response.json();
            } else {
              return { error: { message: "Unauthorized" } };
            }
          })
          .then((result) => {
            localStorage.removeItem("userinfo");
            localStorage.removeItem("authFlowType");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("authFlowSource");
            setLoading(false);
            if (result.error) {
              toast.error(result.error.email);
            } else {
              dispatch(updateUser(result.data.user));
              if (result.data.token != null) {
                Cookie.set("access_token", result.token);
              }
              Cookie.set("user_email", result.data.user.email);
              AccountVarifications();
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    }
  }, []);

  const formatPhoneNumber = (input) => {
    // Remove all non-numeric characters
    if (input) {
      input = input.replace(/\D/g, "");

      if (input.length > 0) {
        input = "(" + input;
      }
      if (input.length > 4) {
        input = input.slice(0, 4) + ") " + input.slice(4);
      }
      if (input.length > 9) {
        input = input.slice(0, 9) + "-" + input.slice(9);
      }
      if (input.length > 14) {
        input = input.slice(0, 14); // Limit to (123) 456-7890 format
      }
    }
    return input;
  };

  return (
    <AuthLayout>
      {loading && (
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "black",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            opacity: 0.8,
            width: { lg: "100%", xs: "100%" },
            height: { lg: "100%", xs: "100%" },
          }}
        >
          <CircularProgress sx={{ color: "#F26F21" }} size={80} />
        </Box>
      )}
      <Container sx={{ py: { lg: 5 } }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{ maxHeight: "100%", minHeight: "100vh" }}
        >
          <Grid item xs={12} md={6} lg={6}>
            <SiteLogo />
            <Grid sx={{ p: 0, display: { xs: "none", md: "block" } }}>
              <ImgWithPopup
                charcterStyle={{ maxWidth: "132%" }}
                style={{ bottom: "-13vh", left: "40%" }}
                img={SignupAvatar}
                msg="signin.signup_msg"
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xlg={6}
            sx={{
              my: "auto",
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "csenter",
              flexDirection: "column",
            }}
          >
            <Item
              sx={{
                pt: 3,
                px: { lg: 4, xs: 2 },
                boxShadow: " 0px 4.472px 11.179px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <Typography
                sx={{
                  fontWeight: { md: 700, xs: 600 },
                  fontSize: { md: "40px", xs: "22px" },
                }}
              >
                {t("signin.signup_title")}
              </Typography>

              {/* Outlook  */}
              <RegisterWithMicrosoft />

              {/* Yahoo  */}
              <Yahoo />

              <RegisterWithGoogle
                setLoading={setLoading}
                googlebutton="signin.google"
                applebutton="signin.apple"
                googlesource="signup"
              />
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
                onSubmit={handleSubmit}
              >
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
                  <Grid item xs={6}>
                    <CustomField
                      label={t("form.fname")}
                      placeholder="John"
                      type="text"
                      id="first_name"
                      name="first_name"
                      onChange={(fname) => setFname(fname)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomField
                      label={t("form.lname")}
                      placeholder="Doe"
                      type="text"
                      id="last_name"
                      name="last_name"
                      onChange={(lname) => setLname(lname)}
                    />
                  </Grid>
                </Grid>

                <CustomField
                  label={t("form.pno")}
                  placeholder={t("form.pnoplaceholder")}
                  type="tel"
                  id="pno"
                  name="pno"
                  value={formatPhoneNumber(pno)}
                  onChange={(inputValue) => {
                    inputValue = formatPhoneNumber(inputValue);
                    setPno(inputValue);
                  }}
                />

                <CustomField
                  label={t("form.email")}
                  placeholder="abc132@email.com"
                  type="email"
                  id="email"
                  name="email"
                  onChange={(email) => setEmail(email)}
                />
                {errors?.email && (
                  <Typography variant="body2" color="error">
                    {errors.email}
                  </Typography>
                )}
                <Password
                  label={t("form.pass")}
                  onPasswordChange={(passwordValue) =>
                    setPasswordValue(passwordValue)
                  }
                  id="pasword"
                  name="password"
                />
                <Password
                  label={t("form.confirmpass")}
                  id="confirm_pasword"
                  name="confirm_pasword"
                  onPasswordChange={(confirmPasswordValue) =>
                    setConfirmPasswordValue(confirmPasswordValue)
                  }
                />
                {!passwordsMatch && (
                  <Typography variant="body2" color="error">
                    Passwords do not match
                  </Typography>
                )}

                {/* <CustomField
                  label={t("form.pno")}
                  placeholder={t("form.pnoplaceholder")}
                  type="tel"
                  onChange={(pno) =>
                    setPno(pno)
                  }
                  id="pno"
                  name="pno"
                /> */}
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          color: "#F6B980",
                          "&.Mui-checked": {
                            color: "#F6B980",
                          },
                        }}
                        name="terms_&_condition"
                        id="terms_&_condition"
                        onChange={handleCheckboxChange}
                        checked={agreeTerms} // Pass state as checked prop
                      />
                    }
                    label={
                      <span>
                        {t("signin.agree")}{" "}
                        <span
                          style={{ color: "#105CA1" }}
                          onClick={TermsCondition}
                        >
                          {t("signin.term")}
                        </span>
                      </span>
                    }
                  />
                </Grid>
                {termsError && (
                  <Typography variant="body2" color="error">
                    {t("Checked a terms & conditions")}
                  </Typography>
                )}
                <Button
                  btnname="button.signup"
                  type="submit"
                  variant="contained"
                  className="Orangebtn"
                  sx={{ py: "10px", fontSize: { lg: "22px", xs: "12px" } }}
                  disabled={!agreeTerms}
                />
              </form>
              <Grid
                item
                sx={{
                  my: 2,
                  color: "black",
                  fontSize: { md: "17px", xs: "12px" },
                }}
              >
                {t("signin.already_account")}{" "}
                <Link to="/signin" style={{ textDecoration: "none" }}>
                  {" "}
                  <span
                    sx={{
                      cursor: "pointer",
                      textAlign: "right",
                      color: "primary.main",
                      fontSize: { md: "17px", xs: "12px" },
                      py: 1,
                    }}
                  >
                    {" "}
                    {t("signin.signin")}
                  </span>
                </Link>
              </Grid>
            </Item>

            <Grid sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <Link to="/contactus" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    mt: 3,
                    fontSize: { md: "18px", sm: "12px" },
                    color: "#F26F21",
                  }}
                >
                  {t("contact.contact")}
                </Typography>
              </Link>
              <Box
                sx={{
                  textAlign: "center",
                  mt: 2,
                }}
              >
                <SelectLanguage />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <TermsAndConditions
          open={showModal}
          handleClose={() => setShowModal(false)}
        />
        <AccountVarification
          open={showAccountVarificationModal}
          handleClose={() => setShowAccountVarificationModal(false)}
          email={email}
        />
      </Container>
    </AuthLayout>
  );
}
