import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { Container, TextField, Typography } from "@mui/material";
import AuthLayout from "../../Layouts/AuthLayout";
import ImgWithPopup from "../../Components/ImgWithPopup";
import SiteLogo from "../../Components/Logo";
import SigninAvatar from "../../Assets/Images/Sigininavatar.png";
import RegisterWithGoogle from "../../Components/RegisterWithGoogle";
import Password from "../../Components/Password";
import CheckboxLabel from "../../Components/Checkbox";
import Button from "../../Components/Button";
import { useTheme } from "@emotion/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ForgetPassword from "./ForgetPassword";
import CustomField from "../../Components/CustomField";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import SelectLanguage from "../../Components/LanguageSelect";
import AccountVarification from "./AccountVarification";
import Cookie from "js-cookie";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "../../Redux/Slices/userSlice";
import toast from "react-hot-toast";
import { postRequest } from "../../Requests/Requests";
import RegisterWithMicrosoft from "./RegisterWithMicrosoft";
import SigninWithMicrosoft from "./SigninWithMicrosoft";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;
const Item = styled(Paper)(({ theme }) => ({
  borderRadius: "19px",
  padding: theme.spacing(1),
}));

export default function SignIn() {
  const [t, i18n] = useTranslation("global");
  const [passwordValue, setPasswordValue] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);
  const [errors, seterrors] = useState(false);
  const [rememberme, setrememberme] = useState(false);

  // const userinfo = useSelector((state) => state.authFlow?.userinfo);
  // const authFlowType = useSelector((state) => state.authFlow?.authFlowType);

  const userinfo = localStorage.getItem("userinfo");
  const authFlowType = localStorage.getItem("authFlowType");

  // React.useEffect(() => {
  //   if (
  //     (Cookie.get("remembermetoken") != "null" ||
  //       Cookie.get("remembermetoken") != null) &&
  //     (sessionStorage.getItem("signedin") == "null" ||
  //       sessionStorage.getItem("signedin") == null)
  //   ) {
  //     setLoading(true);
  //     const myHeaders = new Headers();
  //     myHeaders.append(
  //       `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
  //       `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
  //     );
  //     const formdata = new FormData();

  //     formdata.append("email", Cookie.get("user_email"));
  //     formdata.append("remembermetoken", Cookie.get("remembermetoken"));
  //     const requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: formdata,
  //       redirect: "follow",
  //     };
  //     fetch(`${apiEndpoint}/remembermetoken/verify`, requestOptions)
  //       .then((response) => {
  //         if (response.status == 200) {
  //           return response.json();
  //         } else {
  //           setLoading(false);
  //           return { error: { message: "Unauthorized" } };
  //         }
  //       })
  //       .then((result) => {
  //         if (!result.error) {
  //           Cookie.set("access_token", result?.data?.token, { expires: 14 });
  //           Cookie.set("user_email", result?.data?.user?.email, {
  //             expires: 14,
  //           });
  //           sessionStorage.setItem("signedin", "true");
  //           setLoading(false);
  //           navigate("/Dashboard");
  //         }
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         setLoading(false);
  //       });
  //   } else if (
  //     Cookie.get("remembermetoken") != "null" &&
  //     sessionStorage.getItem("signedin") == "true"
  //   ) {
  //     navigate("/Dashboard");
  //   } else {
  //     console.log("I am here");
  //   }
  // }, []);

  const [showAccountVarificationModal, setShowAccountVarificationModal] =
    useState(false);
  const AccountVarifications = () => {
    setShowAccountVarificationModal(true);
  };
  const forgetpass = () => {
    setShowForgetPasswordModal(true);
  };

  let onRemembermeClick = (e) => {
    setrememberme(e.target.checked);
  };

  let generateRememberMeToken = () => {
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);
    return Array.from(array, (byte) => byte.toString(16).padStart(2, "0")).join(
      ""
    );
  };

  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    setLoading(true); // Set loading to true when form is submitted

    // Validate the password against the regex pattern
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    if (!regex.test(passwordValue)) {
      // If password doesn't meet the criteria, display an error message
      console.error("Password doesn't meet the criteria.");
      setLoading(false);
      // You can display this error message to the user if you want
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      console.error("text doesn't meet the criteria.");
      setLoading(false);
      return;
    }

    const formdata = new FormData();

    formdata.append("email", email);
    formdata.append("password", passwordValue);

    if (rememberme) {
      const token = generateRememberMeToken();
      formdata.append("remembermetoken", token);
      Cookie.set("remembermetoken", token, { expires: 14 });
    }

    postRequest("/login", formdata)
      .then((result) => {
        // console.log(result);
        setLoading(false);
        if (result.error) {
          if (result.error.email == "Email not verified!") {
            dispatch(updateUser(result.data.user));
            Cookie.set("user_email", result.data.user.email);
            Cookie.set("profile_image_url", result.data.user.profile_image_url);
            Cookies.set("access_token", result.data.token, { expires: 14 });
            toast.error("Your email is not verified yet!");
            AccountVarifications();
            return;
          }
          seterrors(result.error);
        } else if (result.data.message == "Preferences not set!") {
          dispatch(updateUser(result.data.user));
          if (result.data.token != null) {
            Cookie.set("access_token", result.data.token, {
              expires: 14,
            });
            Cookie.set("user_id", result.data.user.id, { expires: 14 });

            Cookie.set("user_name", result.data.user.first_name, {
              expires: 14,
            });
            Cookie.set("user_email", result.data.user.email, {
              expires: 14,
            });
          }
          navigate(`/accountconfirmation/subscription/${result.data.user?.id}`);
        } else {
          dispatch(updateUser(result.data.user));
          Cookie.set("access_token", result.data.token, { expires: 14 });
          Cookie.set("user_email", result.data.user.email, { expires: 14 });
          sessionStorage.setItem("signin", false);
          navigate("/Dashboard");
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };

  const theme = useTheme();

  // console.log(authFlowType);

  React.useEffect(() => {
    if (userinfo && authFlowType && authFlowType == "signin") {
      const user = JSON.parse(userinfo);
      const email = user.mail;
      const password = user.id;
      setEmail(email);

      const formdata = new FormData();
      formdata.append("email", email);
      formdata.append("password", password);

      setLoading(true);
      postRequest("/login", formdata)
        .then((result) => {
          setLoading(false);
          localStorage.removeItem("userinfo");
          localStorage.removeItem("authFlowType");
          if (result.error) {
            if (result.error.email == "Email not verified!") {
              dispatch(updateUser(result.data.user));
              Cookie.set("user_email", result.data.user.email);
              Cookie.set(
                "profile_image_url",
                result.data.user.profile_image_url
              );
              Cookies.set("access_token", result.data.token, { expires: 14 });
              toast.error("Your email is not verified yet!");
              AccountVarifications();
              return;
            }
            seterrors(result.error);
          } else if (result.data.message == "Preferences not set!") {
            dispatch(updateUser(result.data.user));
            if (result.data.token != null) {
              Cookie.set("access_token", result.data.token, {
                expires: 14,
              });
              Cookie.set("user_id", result.data.user.id, { expires: 14 });

              Cookie.set("user_name", result.data.user.first_name, {
                expires: 14,
              });
              Cookie.set("user_email", result.data.user.email, {
                expires: 14,
              });
            }
            navigate(
              `/accountconfirmation/subscription/${result.data.user?.id}`
            );
          } else {
            dispatch(updateUser(result.data.user));
            Cookie.set("access_token", result.data.token, { expires: 14 });
            Cookie.set("user_email", result.data.user.email, { expires: 14 });
            sessionStorage.setItem("signin", false);
            navigate("/Dashboard");
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  }, []);

  return (
    <AuthLayout>
      <Container
        zIndex
        sx={{ py: { lg: 5 }, maxHeight: "100%", minHeight: "100vh" }}
      >
        {loading && ( // Conditional rendering of loader
          <Box
            sx={{
              zIndex: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              backgroundColor: "black",
              transform: "translate(-50%, -50%)",
              top: "50%",
              left: "50%",
              opacity: 0.8,
              width: { lg: "100%", xs: "100%" },
              height: { lg: "100%", xs: "100%" },
            }}
          >
            <CircularProgress sx={{ color: "#F26F21" }} size={80} />
          </Box>
        )}
        {/* <Container zIndex sx={{ pb: 0, position: "relative" }}> */}
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          sx={{}}
        >
          <Grid item xs={12} md={6} lg={6}>
            <SiteLogo />
            <Grid sx={{ p: 0, display: { xs: "none", md: "block" } }}>
              <ImgWithPopup
                charcterStyle={{ maxWidth: "110%" }}
                style={{ bottom: "-5vh" }}
                img={SigninAvatar}
                msg="signin.msg"
                xlHeight="30rem"
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            xlg={6}
            sx={{
              my: { lg: "auto" },
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              height: { lg: "90vh", xs: "100vh" },
            }}
          >
            <Item
              container
              sx={{
                pt: 3,
                px: { lg: 4, xs: 2 },
                boxShadow: " 0px 4.472px 11.179px 0px rgba(0, 0, 0, 0.10)",
              }}
            >
              <Typography
                sx={{
                  fontWeight: { md: 700, xs: 600 },
                  fontSize: { md: "40px", xs: "22px" },
                }}
              >
                {t("signin.title")}
              </Typography>

              {/* Outlook  */}
              <SigninWithMicrosoft />
              {/* // google  */}
              <RegisterWithGoogle
                googlebutton="signin.google"
                applebutton="signin.apple"
                googlesource="signin"
                setLoading={setLoading}
              />

              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <CustomField
                  label={t("form.email")}
                  placeholder="abc132@email.com"
                  type="email"
                  id="email"
                  name="email"
                  onChange={(email) => setEmail(email)}
                />

                {errors?.email && (
                  <Typography variant="body2" color="error">
                    {errors.email}
                  </Typography>
                )}

                <Password
                  label={t("form.pass")}
                  id="pasword"
                  name="password"
                  onPasswordChange={(passwordValue) =>
                    setPasswordValue(passwordValue)
                  }
                />
                {errors?.password && (
                  <Typography variant="body2" color="error">
                    {errors.password}
                  </Typography>
                )}
                <Grid container>
                  <Grid item xs={6}>
                    <CheckboxLabel
                      onClick={onRemembermeClick}
                      label="signin.remember"
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      cursor: "pointer",
                      textAlign: "right",
                      color: "primary.main",
                      fontSize: { md: "17px", xs: "12px" },
                      py: 1,
                    }}
                  >
                    <span onClick={forgetpass} style={{}}>
                      {t("signin.forget")}
                    </span>
                  </Grid>
                </Grid>

                <Button
                  btnname="button.login"
                  type="submit"
                  variant="contained"
                  className="Orangebtn"
                  sx={{ py: "10px", fontSize: { lg: "22px", xs: "12px" } }}
                />
              </form>
              <Grid
                item
                sx={{
                  my: 2,
                  color: "black",
                  fontSize: { md: "17px", xs: "12px" },
                }}
              >
                {t("signin.no_account")}{" "}
                <Link to="/SignUp" style={{ textDecoration: "none" }}>
                  {" "}
                  <span
                    sx={{
                      cursor: "pointer",
                      textAlign: "right",
                      color: "primary.main",
                      fontSize: { md: "17px", xs: "12px" },
                      py: 1,
                    }}
                  >
                    {" "}
                    {t("button.signup")}
                  </span>
                </Link>
              </Grid>
            </Item>

            <Grid sx={{ display: "flex", justifyContent: "space-evenly" }}>
              <Link to="/contactus" style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    mt: 3,
                    fontSize: { md: "18px", sm: "12px" },
                    color: "#F26F21",
                  }}
                >
                  {t("contact.contact")}
                </Typography>
              </Link>
              <Box
                sx={{
                  textAlign: "center",
                  mt: 2,
                }}
              >
                <SelectLanguage />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <ForgetPassword
          setShowForgetPasswordModal={setShowForgetPasswordModal}
          open={showForgetPasswordModal}
          setloading={setLoading}
          handleClose={() => setShowForgetPasswordModal(false)}
        />
        <AccountVarification
          email={email}
          open={showAccountVarificationModal}
          handleClose={() => setShowAccountVarificationModal(false)}
        />
      </Container>
    </AuthLayout>
  );
}
