// import * as React from "react";
// import { styled } from "@mui/material/styles";
// import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";
// import useMediaQuery from "@mui/material/useMediaQuery"; // Import useMediaQuery
// import SigninAvatar from "../Assets/Images/Sigininavatar.png";
// import MsgBgImg from "../Assets/Images/msgbg.png";
// import { useTheme } from "@mui/material/styles";
// import { useTranslation } from "react-i18next";

// const styles = {
//   msgBgImg: {
//     maxWidth: "19rem",
//     maxHeight: "100vh",
//     width: "18rem",
//     height: "14rem",
//     position: "relative",
//     // bottom: "-15vh",
//     left: "30%",
//   },
// };

// const Item = styled(Paper)(({ theme }) => ({}));

// export default function ImgWithPopup({ img, msg, style, charcterStyle }) {

//   const [t, i18n] = useTranslation("global");
//   // const theme=useTheme()
//   const theme = useTheme({
//     breakpoints: {
//       values: {
//         xl: "1736px",
//       },
//     },
//   });
//   const isXlScreen = useMediaQuery(theme.breakpoints.up("xl")); 
//   return (
//     <Box sx={{ position: "absolute", bottom: 0, left:"17%"}}>
//       <Grid>
//         <Grid
//           item
//           sx={{ p: 5}}
//           style={{
//             ...styles.msgBgImg,...style,
//             backgroundImage: `url(${MsgBgImg})`,
//             backgroundRepeat: "no-repeat",
//             backgroundSize: "contain",
//           }}
//         >
//           <Box
//             sx={{
//               color: "white",
//               textAlign: "center",
//               fontWeight: "bold",
//               // fontSize: { lg: "20px" },
//             }}
//           >
//             {t(msg)}
//           </Box>
//         </Grid> 
//       </Grid>
//       <Grid container spacing={2}>
//         <Grid item xs={8}>
//           <img
//             src={img}
//             alt="Avatar"
//             style={{
//               ...charcterStyle,
//               // maxWidth: "117%",
//               height: isXlScreen ? "40rem" : "auto",
//             }}
//           />
//         </Grid>
//       </Grid>
//     </Box>
//   );
// }



import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery"; // Import useMediaQuery
import SigninAvatar from "../Assets/Images/Sigininavatar.png";
import MsgBgImg from "../Assets/Images/msgbg.png";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const styles = {
  msgBgImg: {
    maxWidth: "19rem",
    maxHeight: "100vh",
    width: "18rem",
    height: "14rem",
    position: "relative",
    // bottom: "-15vh",
    left: "45%",
  },
};

const Item = styled(Paper)(({ theme }) => ({}));

export default function ImgWithPopup({ img, msg, style, charcterStyle, xlHeight }) {

  const [t, i18n] = useTranslation("global");
  const theme = useTheme({
    breakpoints: {
      values: {
        xl: "1736px",
      },
    },
  });
  const isXlScreen = useMediaQuery(theme.breakpoints.up("xl")); 

  const imageHeight = isXlScreen ? xlHeight : "auto"; // Default to 30rem if xlHeight prop is not provided

  return (
    <Box sx={{ position: "absolute", bottom: 0, left:"17%"}}>
      <Grid>
        <Grid
          item
          sx={{ p: 5}}
          style={{
            ...styles.msgBgImg,...style,
            backgroundImage: `url(${MsgBgImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <Box
            sx={{
              color: "white",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {t(msg)}
          </Box>
        </Grid> 
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <img
            src={img}
            alt="Avatar"
            style={{
              ...charcterStyle,
              height: imageHeight,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
