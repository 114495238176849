import {
  Container,
  Grid,
  Typography,
  Box,
  OutlinedInput,
  InputAdornment,
  Checkbox,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import Button from "../../Components/Button";
import PopoverHover from "../../Components/Popover";
import FormControl, { useFormControl } from "@mui/material/FormControl";
import CheckboxLabel from "../../Components/Checkbox";
import React, { useState, useCallback, useEffect } from "react";
import CustomSelect from "../../Components/CustomSelect";
import { useTranslation } from "react-i18next";
import {
  GoogleMap,
  useJsApiLoader,
  Autocomplete,
  Marker,
  LoadScript,
} from "@react-google-maps/api";
import Cookie from "js-cookie";
import toast from "react-hot-toast";

import { useSelector, useDispatch } from "react-redux";
import { updateStepper4 } from "../../Redux/Slices/preferenceSlice";
import { setSteppers } from "../../Redux/Slices/stepperInfoSlice";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

const timeFormate = [
  { label: "prefrence.none", value: "" },
  { label: "prefrence.minutes", value: "minutes" },
  { label: "prefrence.hours", value: "hours" },
];
const travelMode = [
  {
    label: "prefrence.travelmode1",
    value: "Driving – fastest route (including freeways)",
  },
  {
    label: "prefrence.travelmode2",
    value: "Driving – do not include freeways",
  },
  { label: "prefrence.travelmode3", value: "Public transportation" },
];
const StepperStep4 = ({
  onBack,
  onNext,
  lg = 7,
  show,
  // formData,
  // onFormChange,
  setdefault_invitaion_settings,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [t, i18n] = useTranslation("global");
  const [drive_time_blocked, set_drive_time_blocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user.user);
  const [stepper4Completed, setStepper4Completed] = useState(false);

  const dispatch = useDispatch();
  const reduxFormData = useSelector((state) => state.preferences.stepper4);

  const drivetimeInputClick = (event) => {
    set_drive_time_blocked(true);
    if (event.target.value === "10" || event.target.value === "Minutes") {
    }
  };
  const [appointment_duration_blocked, set_appointment_duration_blocked] =
    useState(false);
  const appointmentdurationInputClick = (event) => {
    set_appointment_duration_blocked(true);
    if (event.target.value === "10" || event.target.value === "Minutes") {
    }
  };
  const [buffer_time_blocked, set_buffer_time_blocked] = useState(false);
  const buffertimeInputClick = (event) => {
    set_buffer_time_blocked(true);
    if (event.target.value === "90" || event.target.value === "Minutes") {
    }
  };
  const [fee_req_blocked, set_fee_req_blocked] = useState(false);
  const feereqInputClick = (event) => {
    set_fee_req_blocked(true);
    if (event.target.value === "$75") {
    }
  };
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCi76nnMh57AEohyRP5kQU2SpH6mMpf3y0", // Replace with your actual API key
    libraries: ["places"],
  });

  const stepperInfo = useSelector((state) => state.stepperInfo.steppers);

  const [formData, setFormData] = useState(
    reduxFormData ?? {
      location: null,
      default_drive_time: true,
      drive_time_number: "",
      drive_time_formate: "",
      travel_mode: "",
      default_Early_Arrival: true,
      early_arrival_number: "",
      early_arrival_time_formate: "",
      close_appointment_default: true,
      close_appointment_number: "",
      close_appointment_time_formate: "",
      buffer_default: true,
      buffer_number: "",
      buffer_time_formate: "",
      fee_req_default: true,
      fee_req: "",
    }
  );

  const onFormChange = (formKey, fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    // if (true) {
    if (stepperInfo && stepperInfo?.stepper_4 && !reduxFormData) {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );
      myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
      const form_data = new FormData();
      form_data.append("email", user?.email);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: form_data,
        redirect: "follow",
      };
      fetch(`${apiEndpoint}/preferences/get/stepper4`, requestOptions)
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 401) {
            setLoading(false);
          }
        })
        .then((result) => {
          if (result.error) {
            setLoading(false);
          } else {
            setLoading(false);
            if (result?.data) {
              setMarkerPosition({
                lat: Number(result?.data?.latitude),
                lng: Number(result?.data?.longitude),
              });
              setMap((prevMap) => {
                if (prevMap) {
                  prevMap.panTo({
                    lat: Number(result?.data?.latitude),
                    lng: Number(result?.data?.longitude),
                  });
                }
                return prevMap;
              });

              setFormData(result?.data);
              dispatch(updateStepper4({ ...result?.data }));
              setStepper4Completed(true);

              setIsAppointment(result?.data.default_Early_Arrival);
              setIsAppointmentClose(result?.data.close_appointment_default);
              setIsBuffer(result?.data.buffer_default);
              setIsFee(result?.data.fee_req_default);
              setIsChecked(result?.data.default_drive_time);
              dispatch(setSteppers(result?.stepperInfo));
            }
          }
        })
        .catch((error) => {
          toast.error("some error occured! refresh the page and try again!");
          console.error(error);
          setLoading(false);
        });
    } else if (reduxFormData) {
      setMarkerPosition({
        lat: Number(formData?.latitude),
        lng: Number(formData?.longitude),
      });
      setMap((prevMap) => {
        if (prevMap) {
          prevMap.panTo({
            lat: Number(formData?.latitude),
            lng: Number(formData?.longitude),
          });
        }
        return prevMap;
      });
    }
  }, []);

  const [map, setMap] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);

  const handleupdate = () => {
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("data", JSON.stringify(formData));
    form_data.append("email", user?.email);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
      redirect: "follow",
    };
    fetch(`${apiEndpoint}/preferences/update/stepper4`, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 401) {
          setLoading(false);
        }
      })
      .then((result) => {
        if (result.error) {
          toast.error(result.error);
          toast.error(result.error);
          setLoading(false);
        } else {
          setLoading(false);
          if (result?.data) {
            setFormData(result.data);
            dispatch(updateStepper4({ ...result?.data }));
            toast.success("Updated");
          }
        }
      })
      .catch((error) => {
        toast.error("some error occured! refresh the page and try again!");
        console.error(error);
        setLoading(false);
      });
  };

  const onLoad = useCallback((autocompleteInstance) => {
    setAutocomplete(autocompleteInstance);
  }, []);
  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        onFormChange("stepper4", "location", place.formatted_address);
        onFormChange("stepper4", "latitude", lat);
        onFormChange("stepper4", "longitude", lng);
        setLocationError(false);
        setMarkerPosition({ lat, lng });
        setMap((prevMap) => {
          if (prevMap) {
            prevMap.panTo({ lat, lng });
          }
          return prevMap;
        });
      }
    }
  };

  const [locationError, setLocationError] = useState(false);
  const [travelModeError, setTravelModeError] = useState(false);
  const handleNext = () => {
    if (formData.location.trim() === "" || formData.travel_mode.trim() === "") {
      setLocationError(formData.location.trim() === "");
      setTravelModeError(formData.travel_mode.trim() === "");
    } else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );
      myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
      const form_data = new FormData();
      form_data.append("data", JSON.stringify(formData));
      form_data.append("email", user?.email);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: form_data,
        redirect: "follow",
      };
      fetch(`${apiEndpoint}/preferences/add/stepper4`, requestOptions)
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 401) {
            setLoading(false);
          }
        })
        .then((result) => {
          // console.log(result);
          if (result.error) {
            toast.error(result.error);
            setLoading(false);
          } else {
            dispatch(updateStepper4({ ...result?.data }));
            dispatch(setSteppers(result?.stepperInfo));
            onNext();
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };

  const [isChecked, setIsChecked] = useState(formData?.default_drive_time);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    onFormChange("stepper4", "default_drive_time", event.target.checked);
    set_drive_time_blocked(false);

    setdefault_invitaion_settings(event.target.checked);
  };
  const [isAppointment, setIsAppointment] = useState(
    formData?.default_Early_Arrival
  );

  const handleAppointmentChange = (event) => {
    setIsAppointment(event.target.checked);
    onFormChange("stepper4", "default_Early_Arrival", event.target.checked);
    setdefault_invitaion_settings(event.target.checked);
  };
  const [isAppointmentClose, setIsAppointmentClose] = useState(
    formData?.close_appointment_default
  );

  const handleAppointmentCloseChange = (event) => {
    setIsAppointmentClose(event.target.checked);
    onFormChange("stepper4", "close_appointment_default", event.target.checked);
    setdefault_invitaion_settings(event.target.checked);
    set_appointment_duration_blocked(false);
  };
  const [isBuffer, setIsBuffer] = useState(formData?.buffer_default);

  const handleBufferChange = (event) => {
    setIsBuffer(event.target.checked);
    onFormChange("stepper4", "buffer_default", event.target.checked);
    setdefault_invitaion_settings(event.target.checked);
    set_buffer_time_blocked(false);
  };
  const [isFee, setIsFee] = useState(formData?.fee_req_default);

  const handleFeeChange = (event) => {
    setIsFee(event.target.checked);
    onFormChange("stepper4", "fee_req_default", event.target.checked);
    setdefault_invitaion_settings(event.target.checked);
    set_fee_req_blocked(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const isInputFilled = inputValue.trim() !== "";

  // if (loadError) {
  //   return <div>{t("prefrence.map_load_error")}</div>;
  // }
  const libraries = ["places"];
  return (
    <Container>
      <Typography
        sx={{
          mx: { lg: "auto", xs: 3 },
          textAlign: "center",
          fontSize: { lg: "40px", xs: "18px" },
          fontWeight: { lg: 700, xs: 600 },
        }}
      >
        {show && <>{t("prefrence.step4_title")}</>}
      </Typography>
      <Grid container>
        <Grid
          item
          lg={lg}
          xs={12}
          sx={{
            backgroundColor: "white",
            mx: "auto",
            my: 2,
            p: { lg: 3, xs: 2 },
            borderRadius: "8px",
            position: "relative",
          }}
        >
          <form>
            {loading && ( // Conditional rendering of loader
              <Box
                sx={{
                  zIndex: 5,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  backgroundColor: "transparent",
                  transform: "translate(-50%, -50%)",
                  top: "50%",
                  left: "50%",
                  opacity: 0.8,
                  width: { lg: "100%", xs: "100%" },
                  height: { lg: "100%", xs: "100%" },
                }}
              >
                <CircularProgress sx={{ color: "#F26F21" }} size={80} />
              </Box>
            )}
            <Typography
              sx={{
                my: 1,
                fontSize: { lg: "25px", xs: "14px" },
                fontWeight: { lg: 600, xs: 500 },
                display: "flex",
              }}
            >
              {t("prefrence.startloc")}

              <PopoverHover text="prefrence.startloc_popover" />
            </Typography>
            <FormControl sx={{ width: { lg: "50%", xs: "80%" } }}>
              {isLoaded && (
                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                  <OutlinedInput
                    type="text"
                    placeholder={t("prefrence.enterloc")}
                    sx={{ borderRadius: "11px", my: 1 }}
                    required
                    id="location"
                    name="location"
                    value={formData.location}
                    onChange={(e) => {
                      onFormChange("stepper4", "location", e.target.value);
                      setLocationError(false);
                    }}
                  />
                </Autocomplete>
              )}
              {locationError && (
                <FormHelperText error>
                  <Typography>kindly first fill this fileld</Typography>
                </FormHelperText>
              )}
            </FormControl>
            {isLoaded && (
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "200px" }}
                center={markerPosition || { lat: 42.4072, lng: 71.3824 }}
                zoom={20}
                onLoad={(map) => {
                  setMap(map);
                }}
              >
                {markerPosition && <Marker position={markerPosition} />}
              </GoogleMap>
            )}
            <Typography
              sx={{
                mt: 1,
                fontSize: { lg: "25px", xs: "14px" },
                fontWeight: { lg: 600, xs: 500 },
                display: "flex",
              }}
            >
              {t("prefrence.maxstart")}

              <PopoverHover text="prefrence.maxstart_popover" />
            </Typography>

            <FormControl component="fieldset" sx={{ px: 0 }}>
              <label>
                <Checkbox
                  checked={isChecked}
                  // checked={
                  //   typeof formData.buffer_default == Boolean
                  //     ? formData.buffer_default
                  //     : formData.buffer_default == "true"
                  //     ? true
                  //     : false
                  // }
                  onChange={handleCheckboxChange}
                  name="default_drive_time"
                  id="default_drive_time"
                  sx={{
                    color: "#F6B980",
                    pl: 0,
                    "&.Mui-checked": {
                      color: "#F6B980",
                    },
                  }}
                />
                {t("step3.default_setting")}
              </label>
            </FormControl>
            {drive_time_blocked && (
              <>
                <Typography color="error">First Uncheck a checkbox</Typography>
              </>
            )}

            {isChecked ? (
              <Grid
                className="drivtimechecked"
                sx={{ display: "flex", gap: 2 }}
              >
                <FormControl sx={{ width: { lg: "25%", xs: "30%" } }}>
                  <OutlinedInput
                    value="10"
                    sx={{ borderRadius: "11px", my: 1 }}
                    onClick={drivetimeInputClick}
                  />
                </FormControl>
                <Grid item lg={3} xs={6}>
                  <OutlinedInput
                    value={t("prefrence.minutes")}
                    sx={{ borderRadius: "11px", my: 1 }}
                    onClick={drivetimeInputClick}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: { lg: "25%", xs: "30%" } }}>
                  <OutlinedInput
                    type="number"
                    name="drive_time_number"
                    id="drive_time_number"
                    placeholder="10"
                    sx={{ borderRadius: "11px", my: 1 }}
                    value={formData.drive_time_number}
                    onChange={(e) => {
                      onFormChange(
                        "stepper4",
                        "drive_time_number",
                        e.target.value
                      );
                    }}
                  />
                </FormControl>
                <Grid item lg={3} xs={6}>
                  <CustomSelect
                    label={t("prefrence.time_formate")}
                    name="drive_time_formate"
                    id="drive_time_formate"
                    menuItems={timeFormate}
                    required={true}
                    sx={{}}
                    value={formData.drive_time_formate}
                    onChange={(e) => {
                      onFormChange(
                        "stepper4",
                        "drive_time_formate",
                        e.target.value
                      );
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Typography
              sx={{
                mt: 1,
                fontSize: { lg: "25px", xs: "14px" },
                fontWeight: { lg: 600, xs: 500 },
                display: "flex",
              }}
            >
              {t("prefrence.travelmode")}

              <PopoverHover text="prefrence.travelmode_popover" />
            </Typography>
            <Grid sx={{}}>
              <Grid item xs={8} lg={6}>
                <CustomSelect
                  label={t("prefrence.travelmode_label")}
                  menuItems={travelMode}
                  required={true}
                  id="travel_mode"
                  name="travel_mode"
                  value={formData.travel_mode} // Use formData for value
                  onChange={(e) => {
                    onFormChange("stepper4", "travel_mode", e.target.value);
                    setTravelModeError(false);
                  }}
                />
                {travelModeError && (
                  <FormHelperText error>
                    <Typography>kindly first fill this fileld</Typography>
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Typography
              sx={{
                mt: 1,
                fontSize: { lg: "25px", xs: "14px" },
                fontWeight: { lg: 600, xs: 500 },
                display: "flex",
              }}
            >
              {t("prefrence.earlyarrival")}

              <PopoverHover text="prefrence.earlyarrival_popover" />
            </Typography>

            <FormControl component="fieldset" sx={{ px: 0 }}>
              <label>
                <Checkbox
                  checked={isAppointment}
                  // checked={
                  //   formData.default_Early_Arrival == "true" ? true : false
                  // }
                  onChange={handleAppointmentChange}
                  name="default_Early_Arrival"
                  id="default_Early_Arrival"
                  sx={{
                    color: "#F6B980",
                    pl: 0,
                    "&.Mui-checked": {
                      color: "#F6B980",
                    },
                  }}
                />
                {t("step3.default_setting")}
              </label>
            </FormControl>

            {isAppointment ? (
              <Grid sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: { lg: "25%", xs: "30%" } }}>
                  <OutlinedInput
                    value="10"
                    sx={{ borderRadius: "11px", my: 1 }}
                  />
                </FormControl>
                <Grid item lg={3} xs={6}>
                  <OutlinedInput
                    value={t("prefrence.minutes")}
                    sx={{ borderRadius: "11px", my: 1 }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: { lg: "25%", xs: "30%" } }}>
                  <OutlinedInput
                    name="early_arrival_number"
                    id="early_arrival_number"
                    type="number"
                    placeholder="10"
                    sx={{ borderRadius: "11px", my: 1 }}
                    value={formData.early_arrival_number}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        onFormChange("stepper4", "early_arrival_number", value);
                      }
                    }}
                  />
                </FormControl>
                <Grid item lg={3} xs={6}>
                  <CustomSelect
                    label={t("prefrence.time_formate")}
                    menuItems={timeFormate}
                    required={false}
                    sx={{}}
                    id="early_arrival_time_formate"
                    name="early_arrival_time_formate"
                    value={formData.early_arrival_time_formate}
                    onChange={(e) => {
                      onFormChange(
                        "stepper4",
                        "early_arrival_time_formate",
                        e.target.value
                      );
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Typography
              sx={{
                mt: 1,
                fontSize: { lg: "25px", xs: "14px" },
                fontWeight: { lg: 600, xs: 500 },
                display: "flex",
              }}
            >
              {t("prefrence.clossing_appointment")}
              <PopoverHover text="prefrence.clossing_appointment_popover" />
            </Typography>
            <FormControl component="fieldset" sx={{ px: 0 }}>
              <label>
                <Checkbox
                  name="close_appointment_default"
                  // checked={
                  //   formData.close_appointment_default == "true" ? true : false
                  // }
                  id="close_appointment_default"
                  checked={isAppointmentClose}
                  onChange={handleAppointmentCloseChange}
                  sx={{
                    color: "#F6B980",
                    pl: 0,
                    "&.Mui-checked": {
                      color: "#F6B980",
                    },
                  }}
                />
                {t("step3.default_setting")}
              </label>
            </FormControl>
            {appointment_duration_blocked && (
              <>
                <Typography color="error">First Uncheck a checkbox</Typography>
              </>
            )}
            {isAppointmentClose ? (
              <Grid sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: { lg: "25%", xs: "30%" } }}>
                  <OutlinedInput
                    value="10"
                    sx={{ borderRadius: "11px", my: 1 }}
                    onClick={appointmentdurationInputClick}
                  />
                </FormControl>
                <Grid item lg={3} xs={6}>
                  <OutlinedInput
                    value={t("prefrence.minutes")}
                    sx={{ borderRadius: "11px", my: 1 }}
                    onClick={appointmentdurationInputClick}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: { lg: "25%", xs: "30%" } }}>
                  <OutlinedInput
                    name="close_appointment_number"
                    id="close_appointment_number"
                    type="number"
                    placeholder="10"
                    sx={{ borderRadius: "11px", my: 1 }}
                    value={formData.close_appointment_number}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        onFormChange(
                          "stepper4",
                          "close_appointment_number",
                          value
                        );
                      }
                    }}
                  />
                </FormControl>
                <Grid item lg={3} xs={6}>
                  <CustomSelect
                    label={t("prefrence.time_formate")}
                    menuItems={timeFormate}
                    required={false}
                    sx={{}}
                    name="close_appointment_time_formate"
                    id="close_appointment_time_formate"
                    value={formData.close_appointment_time_formate}
                    onChange={(e) => {
                      onFormChange(
                        "stepper4",
                        "close_appointment_time_formate",
                        e.target.value
                      );
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Typography
              sx={{
                mt: 1,
                fontSize: { lg: "25px", xs: "14px" },
                fontWeight: { lg: 600, xs: 500 },
                display: "flex",
              }}
            >
              {t("prefrence.buffer_time")}
              <PopoverHover text="prefrence.buffer_time_popover" />
            </Typography>
            <FormControl component="fieldset" sx={{ px: 0 }}>
              <label>
                <Checkbox
                  name="buffer_default"
                  id="buffer_default"
                  checked={isBuffer}
                  // checked={formData.buffer_default == "true" ? true : false}
                  onChange={handleBufferChange}
                  sx={{
                    color: "#F6B980",
                    pl: 0,
                    "&.Mui-checked": {
                      color: "#F6B980",
                    },
                  }}
                />
                {t("step3.default_setting")}
              </label>
            </FormControl>
            {buffer_time_blocked && (
              <>
                <Typography color="error">First Uncheck a checkbox</Typography>
              </>
            )}
            {isBuffer ? (
              <Grid sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: { lg: "25%", xs: "30%" } }}>
                  <OutlinedInput
                    value="90"
                    sx={{ borderRadius: "11px", my: 1 }}
                    onClick={buffertimeInputClick}
                  />
                </FormControl>
                <Grid item lg={3} xs={6}>
                  <OutlinedInput
                    value={t("prefrence.minutes")}
                    sx={{ borderRadius: "11px", my: 1 }}
                    onClick={buffertimeInputClick}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: { lg: "25%", xs: "30%" } }}>
                  <OutlinedInput
                    name="buffer_number"
                    id="buffer_number"
                    type="number"
                    placeholder="10"
                    sx={{ borderRadius: "11px", my: 1 }}
                    value={formData.buffer_number}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        onFormChange("stepper4", "buffer_number", value);
                      }
                    }}
                  />
                </FormControl>
                <Grid item lg={3} xs={6}>
                  <CustomSelect
                    label={t("prefrence.time_formate")}
                    menuItems={timeFormate}
                    required={false}
                    sx={{}}
                    id="buffer_time_formate"
                    name="buffer_time_formate"
                    value={formData.buffer_time_formate}
                    onChange={(e) => {
                      onFormChange(
                        "stepper4",
                        "buffer_time_formate",
                        e.target.value
                      );
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <Typography
              sx={{
                mt: 1,
                fontSize: { lg: "25px", xs: "14px" },
                fontWeight: { lg: 600, xs: 500 },
                display: "flex",
              }}
            >
              {t("prefrence.fee_req")}
              <PopoverHover text="prefrence.fee_req_popover" />
            </Typography>
            <FormControl component="fieldset" sx={{ px: 0 }}>
              <label>
                <Checkbox
                  checked={isFee}
                  // checked={formData.fee_req_default == "true" ? true : false}
                  name="fee_req_default"
                  id="fee_req_default"
                  onChange={handleFeeChange}
                  sx={{
                    color: "#F6B980",
                    pl: 0,
                    "&.Mui-checked": {
                      color: "#F6B980",
                    },
                  }}
                />
                {t("step3.default_setting")}
              </label>
            </FormControl>
            {fee_req_blocked && (
              <>
                <Typography color="error">First Uncheck a checkbox</Typography>
              </>
            )}
            {isFee ? (
              <Grid sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: { lg: "25%", xs: "30%" } }}>
                  <OutlinedInput
                    value="$75"
                    sx={{ borderRadius: "11px", my: 1 }}
                    onClick={feereqInputClick}
                  />
                </FormControl>
              </Grid>
            ) : (
              <Grid sx={{ display: "flex", gap: 2 }}>
                <FormControl sx={{ width: { lg: "30%", xs: "60%" } }}>
                  <OutlinedInput
                    name="fee_req"
                    id="fee_req"
                    type="number"
                    placeholder="75"
                    startAdornment={
                      <InputAdornment position="start">$</InputAdornment>
                    }
                    sx={{ borderRadius: "11px", my: 1 }}
                    value={formData.fee_req}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d*$/.test(value)) {
                        onFormChange("stepper4", "fee_req", value);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            <Box
              sx={{
                display: "flex",
                pt: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                btnname="button.back"
                type="submit"
                onClick={onBack}
                variant="contained"
                className="greybtn"
                sx={{
                  px: { lg: 7, xs: 3 },
                  py: 1.2,
                  fontSize: { lg: "16px", xs: "12px" },
                  mx: 1,
                  borderRadius: "8px",
                }}
              />
              {stepperInfo?.stepper_4 ? (
                <>
                  <Button
                    btnname="udpate"
                    onClick={handleupdate}
                    type="button"
                    variant="contained"
                    className="Orangebtn"
                    sx={{
                      px: { lg: 7, xs: 3 },
                      py: 1.2,
                      fontSize: { lg: "16px", xs: "12px" },
                      mx: 1,
                      borderRadius: "8px",
                    }}
                  />
                  <Button
                    btnname="button.next"
                    onClick={() => onNext()}
                    type="button"
                    variant="contained"
                    className="Orangebtn"
                    sx={{
                      px: { lg: 7, xs: 3 },
                      py: 1.2,
                      fontSize: { lg: "16px", xs: "12px" },
                      mx: 1,
                      borderRadius: "8px",
                    }}
                  />
                </>
              ) : (
                <Button
                  btnname="button.next"
                  onClick={handleNext}
                  type="button"
                  variant="contained"
                  className="Orangebtn"
                  sx={{
                    px: { lg: 7, xs: 3 },
                    py: 1.2,
                    fontSize: { lg: "16px", xs: "12px" },
                    mx: 1,
                    borderRadius: "8px",
                  }}
                />
              )}
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StepperStep4;
