// import * as React from "react";
// import {
//   FormControl,
//   InputLabel,
//   OutlinedInput,
//   Typography,
// } from "@mui/material";
// import { useTranslation } from "react-i18next";

// export default function CustomField({
//   label,
//   placeholder,
//   type,
//   sx,
//   onChange,
//   name, id, InputProps,
// }) {
//   const [t, i18n] = useTranslation("global");
//   const [value, setValue] = React.useState("");
//   const [error, setError] = React.useState("");

//   const handleBlur = () => {
//     if (type === "text") {
//       const textregex = /^(?!.*\d)[a-zA-Z]{3,}$/;
//       if (!textregex.test(value)) {
//         setError("Input accept only alphabates and should be more than two");
//       } else {
//         setError("");
//       }
//     }

//     // if (type === "text") {
//     //   if (value.trim().length < 3) {
//     //     setError("Input should be more than 2 characters");
//     //   } else {
//     //     setError("");
//     //   }
//     // }
//     else if (type === "tel") {
//       const phoneRegex =
//         /^\+?[0-9]{1,3}?[-.●]?([0-9]{3})[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
//       if (!phoneRegex.test(value)) {
//         setError("phone number in +923679855432 format");
//       } else {
//         setError("");
//       }
//     }
//     else if (type === "email") {
//       const emailRegex =
//         /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
//       if (!emailRegex.test(value)) {
//         setError("Email must be in form of example@gmail.com");
//       } else {
//         setError("");
//       }
//     }
//   };
//   const handleChange = (event) => {
//     if (event.target.type === "tel") {
//       // Remove non-numeric characters from the input value, allowing "+" at the start
//       const numericValue = event.target.value.replace(/[^+\d]/g, '');
//       setValue(numericValue);
//       if (onChange) {
//         onChange(numericValue);
//       }
//     } else {
//       setValue(event.target.value);
//       if (onChange) {
//         onChange(event.target.value);
//       }
//     }
//   };

//   return (
// <FormControl
//   sx={{ my: 1, width: "25ch", width: "100%", ...sx }}
//   variant="outlined"
//   error={Boolean(error)}
// >
//   <InputLabel htmlFor="outlined-adornment-input">{label}</InputLabel>
//   <OutlinedInput
//     sx={{ borderRadius: "8px" }}
//     id={id}
//     name={name}
//     type={type}
//     placeholder={t(placeholder)}
//     required
//     onBlur={handleBlur}
//     onChange={handleChange}
//     InputProps={InputProps}
//     value={value}
//     label={t(label)}
//   />
//   {error && (
//     <Typography variant="body2" color="error">
//       {error}
//     </Typography>
//   )}
//   {!error && <Typography variant="body2" color="success"></Typography>}
// </FormControl>
//   );
// }

import * as React from "react";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function CustomField({
  label,
  placeholder,
  type,
  sx,
  onChange,
  value,
  name,
  id,
  InputProps,
}) {
  const [t, i18n] = useTranslation("global");
  const [error, setError] = React.useState("");

  const handleBlur = () => {
    if (type === "text") {
      const textregex = /^(?!.*\d)[a-zA-Z]{3,}$/;
      if (!textregex.test(value)) {
        setError("Input accept only alphabates and should be more than two");
      } else {
        setError("");
      }
    } else if (type === "tel") {
      // const phoneRegex =
      //   /^\+?1?[-.●]?([0-9]{3})[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
      // if (!phoneRegex.test(value)) {
      //   setError("Please enter a valid phone number");
      // } else {
      //   setError("");
      // }
    }
  };

  return (
    <FormControl
      sx={{ my: 1, width: "25ch", width: "100%", ...sx }}
      variant="outlined"
      error={Boolean(error)}
    >
      <InputLabel htmlFor="outlined-adornment-input">{label}</InputLabel>
      <OutlinedInput
        sx={{ borderRadius: "8px" }}
        id={id}
        name={name}
        type={type}
        placeholder={t(placeholder)}
        required
        onBlur={handleBlur}
        onChange={(e) => onChange(e.target.value)}
        readOnly={InputProps?.readOnly}
        value={value}
        label={t(label)}
      />
      {error && (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      )}
      {!error && <Typography variant="body2" color="success"></Typography>}
    </FormControl>

    // <FormControl fullWidth sx={{ my: 2 }}>
    //   <InputLabel>{label}</InputLabel>
    //   <OutlinedInput
    //     placeholder={placeholder}
    //     type={type}
    //     sx={sx}
    //     value={value}
    //     onChange={(e) => onChange(e.target.value)}
    //     onBlur={handleBlur}
    //     error={!!error}
    //     id={id}
    //     name={name}
    //     {...InputProps}
    //   />
    //   {error && (
    //     <Typography variant="body2" color="error">
    //       {error}
    //     </Typography>
    //   )}
    // </FormControl>
  );
}
