import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import AppleLogin from "react-apple-login";
import Cookie from "js-cookie";
import AccountVarification from "./AccountVarification";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { updateUser } from "../../Redux/Slices/userSlice";
import { jwtDecode } from "jwt-decode";
import GetEmailModal from "./GetEmailModal";
import { setAuthFlowType } from "../../Redux/Slices/authFlowSlice";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

export default function SigninWithMicrosoft({ setLoading }) {
  const [t, i18n] = useTranslation("global");
  const [errors, setErrors] = useState(false);

  const dispatch = useDispatch();
  const [showAccountVarificationModal, setShowAccountVarificationModal] =
    useState(false);
  const [email, setemail] = useState(null);
  const [password, setpassword] = useState(null);
  const navigate = useNavigate();
  const theme = useTheme();

  // Function to generate a random code verifier (43 to 128 characters)
  function generateCodeVerifier() {
    const randomArray = new Uint8Array(32); // 32 bytes generates a string of ~43 characters
    window.crypto.getRandomValues(randomArray);
    return base64URLEncode(randomArray);
  }

  // Function to hash the code verifier using SHA-256 and then base64 encode it
  async function generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    return base64URLEncode(new Uint8Array(hashBuffer));
  }

  // Function to URL-safe Base64 encode a string
  function base64URLEncode(arrayBuffer) {
    return btoa(String.fromCharCode.apply(null, arrayBuffer))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }
  const clientId = "e300ce34-2d3a-45ea-afba-ff68965a608f";
  const redirectUri = "http://localhost:3000/outlook/callback"; // Redirect URL should match what you set in Azure Portal
  const scopes = "openid profile email Mail.Read Mail.Send User.Read";

  const handleOutlookLogin = async () => {
    const verifier = generateCodeVerifier();
    const challenge = await generateCodeChallenge(verifier);
    localStorage.setItem("code_verifier", verifier);

    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_mode=query&scope=${encodeURIComponent(
      scopes
    )}&code_challenge=${challenge}&code_challenge_method=S256`;

    // dispatch(setAuthFlowType("signin"));
    localStorage.setItem("authFlowType", "signin");
    window.location.href = authUrl;
  };

  return (
    <Box sx={{ "& button": { my: 1 } }}>
      <Button
        className="greybtn"
        onClick={handleOutlookLogin}
        sx={{
          width: "100%",
          py: "10px",
          fontSize: { xs: "12px", md: "20px" },
          borderRadius: "8px",
        }}
        size="large"
        variant="contained"
        // startIcon={
        //   <img
        //     src={GoogleIcon}
        //     alt="Google Icon"
        //     style={{ width: "28px", height: "28px" }}
        //   />
        // }
      >
        Signin with Microsoft
      </Button>
    </Box>
  );
}
