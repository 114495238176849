import { Grid, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React from 'react'

import TextImageviseversa from '../Components/TextImageviseversa.jsx'

import job1 from '../Assets/Images/job1.png';
import job2 from '../Assets/Images/job2.png';
import job3 from '../Assets/Images/job13.png';
import job4 from '../Assets/Images/job4.png';
import job5 from '../Assets/Images/job5.png';
import job6 from '../Assets/Images/job6.png';
import job7 from '../Assets/Images/job7.png';
import Card from '../Components/Card.jsx';



const Jobmanagment = () => {

    return (
        <>
            <Box sx={{ background: '#ffffff', paddingTop: '100px' }}>
                <Container >
                    <Grid container spacing={2}>
                        {/* First Row: Single Column */}
                        <Grid item xs={12}>
                            <Box sx={{ padding: '10px 25px' }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        fontSize: { xs: '32px', md: '40px' }, // Responsive font size
                                        color: '#000',
                                        fontWeight: 600,
                                        textAlign: 'center',
                                    }}
                                >
                                    Effortless Job Management: How <span style={{ color: '#F26F21' }}>GoSignings</span>  Optimizes Your Notary Schedule
                                </Typography>
                            </Box>
                        </Grid>

                        {/* Third Row: Two Columns */}

                        <Grid item xs={12} md={12}>
                            <Box sx={{ padding: { md: '20px' } }}>
                                <TextImageviseversa
                                    image={job1}
                                    heading='Your Daily Overview at a Glance'
                                    text='See your entire day in one place. The GoSignings dashboard offers a comprehensive view of your appointments and pending job invitations, so youre always in control of your schedule.'
                                    reverse={false}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Box sx={{ padding: { md: '20px' } }}>
                                <TextImageviseversa
                                    image={job2}
                                    heading='Instant Job Invitations'
                                    text='"Receive new job offers in real-time with all the details you need to make an informed decision. Accept or decline invitations directly from the pop-up window."'
                                    reverse={true}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Box sx={{ padding: { md: '20px' } }}>
                                <TextImageviseversa
                                    image={job3}
                                    heading='Drive Time Calculation'
                                    text='GoSignings calculates the drive time to and from each job, ensuring you have enough time to arrive on schedule. No more guesswork just precise planning.'
                                    reverse={false}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ padding: { md: '20px' } }}>
                                <TextImageviseversa
                                    image={job4}
                                    heading='Automatic Job Acceptance'
                                    text='Automatic Scheduling, SimplifiedContent: "If the drive time fits, GoSignings automatically adds the job to your calendar. Its that easy—no manual entry needed. Keep your day flowing smoothly with automated job acceptance."'
                                    reverse={true}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Box sx={{ padding: { md: '20px' } }}>
                                <TextImageviseversa
                                    image={job5}
                                    heading='Updated Calendar View'
                                    text='Watch your calendar update instantly with the new job. All your appointments are color-coded and organized, making it simple to manage your day at a glance.'
                                    reverse={false}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} md={12}>
                            <Box sx={{ padding: { md: '20px' } }}>
                                <TextImageviseversa
                                    image={job6}
                                    heading='Order Master Record'
                                    text='Every job you accept generates a detailed order master record. Access all client information, instructions, and job details in one easy-to-navigate location.'
                                    reverse={true}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Box sx={{ padding: { md: '20px' } }}>
                                <TextImageviseversa
                                    image={job7}
                                    heading='Confirmation and Notifications'
                                    text='Receive immediate confirmation when a job is added to your schedule, along with timely notifications for any changes. Stay on top of your commitments without missing a beat.'
                                    reverse={false}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    <Card
                        heading="Start Your Free 30-Day Trial "
                        sxh={{
                            fontSize: { xs: '36px', sm: '48px', md: '55px' }, // Responsive font size
                            fontWeight: '600',
                            marginBottom: '10px',
                        }}
                        text="(no credit card required)"
                        sxt={{
                            fontSize: { xs: '28px', sm: '32px' }, // Responsive font size
                            fontWeight: '400',
                            lineHeight: '1.5',
                            marginBottom: '20px'
                        }}
                        height="300px" // Custom height
                        name='Register'
                        sxb={{
                            width: '250px',
                            height: '50px',
                            fontSize: '24px',
                            fontWeight: '400',
                            textTransform: 'none',
                            borderRadius: '10px',
                        }}
                    />
                </Container>
            </Box>
        </>
    )
}

export default Jobmanagment; 