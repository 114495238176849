import axios from "axios";
import Cookie from "js-cookie";
const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;

// Create an Axios instance
const setHeaders = () => {
  const headers = new Headers();
  headers.append(
    process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD,
    `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
  );
  headers.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
  return headers;
};

// Wrapper function for POST requests using fetch
const postRequest = async (url, formData) => {
  const headers = setHeaders();

  const requestOptions = {
    method: "POST",
    headers: headers,
    body: formData,
  };

  try {
    const response = await fetch(`${apiEndpoint}${url}`, requestOptions);
    const data = await response.json();
    // console.log("response");
    // console.log(data);
    if (response.status === 200) {
      return data;
    } else if (response.status === 401) {
      window.location.href = "/signin";
    } else {
      return { error: "unauthorized" };
    }
  } catch (error) {
    console.error(error);
    // Handle or throw the error if needed
  }
};

// Wrapper function for GET requests using fetch
const getRequest = async (url, params = {}) => {
  const headers = setHeaders();

  // Construct query string from params
  const queryString = new URLSearchParams(params).toString();
  const fullUrl = `${apiEndpoint}${url}${queryString ? `?${queryString}` : ""}`;

  try {
    const response = await fetch(fullUrl, {
      method: "GET",
      headers: headers,
    });
    const data = await response.json();

    if (response.status === 200) {
      return data;
    }
    // Handle other status codes as needed
  } catch (error) {
    console.error("Error during API call:", error);
    // Handle or throw the error if needed
  }
};

export { postRequest, getRequest };
