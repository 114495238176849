import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const TextImageviseversa = ({ image, heading, text, reverse }) => {
  return (
    <Box
      sx={{
        padding: {md: '20px'},
        
        marginBottom: '20px',
      }}
    >
      <Grid container spacing={6} direction={reverse ? 'row-reverse' : 'row'}>
        <Grid item xs={12} md={6} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'left'}}>
          <Typography
            variant="h4"
            sx={{ color: '#000000', fontFamily: 'Inter', fontWeight: 600, fontSize: '36px' }}
          >
            {heading}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: '#000000', fontFamily: 'Inter', marginTop: '16px', fontSize: '24px', lineHeight: '131%' }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={image}
            alt={heading}
            sx={{ width: '100%', height: 'auto', borderRadius: '8px' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TextImageviseversa;
