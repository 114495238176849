import { Typography } from "@mui/material";
import { Box, height } from "@mui/system";
import React from "react";
const LandingSlider = ({ icon, heading, paragraph, backgroundColor }) => {
  return (
    <Box
      //   xs={2}
      //   className="col-11 text-center justify-content-center py-4 px-4 "
      sx={{
        minHeight: {lg:"300px", xs:"200px"},
        maxHeight: {xs:"300px"},
        mx: 1.5,
        justifyContent: "",
        p: 4,
        backgroundColor: backgroundColor || "white",
        borderRadius: '10px',
        padding: '25px 15px'
      }}
    >
      <Box sx={{ display: "flex" }}>
        {/* <img src={icon} alt="" style={{width: "45px", height: "45px"}} /> */}
        <Box sx={{}}>
          <Typography sx={{ fontSize: { lg: "22px", xs: "22px" }, color: '#FFFFFF', mb: '10px', fontWeight: '500' }}>
            {heading}
          </Typography>
          {/* ⭐⭐⭐⭐ */}
        </Box>
      </Box>
      <Typography sx={{ fontSize: { lg: "18px", xs: "18px" }, color: '#FFFFFF', fontWeight: 'normal' }}>
        {paragraph}
      </Typography>
    </Box>
  );
};
export default LandingSlider;
