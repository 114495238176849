import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
// import LanguageContext from "../../Context/LanguageContext";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Grid,
  Button,
  Toolbar,
  Typography,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "../Assets/Icons/dashboardicon.svg";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import AccountIcons from "../Assets/Icons/account.svg";
import RefrenceIcon from "../Assets/Icons/refrenceicon.svg";
import InboxIcon from "../Assets/Icons/inboxicon.svg";
import SettingIcon from "../Assets/Icons/settingicon.svg";
import HelpIcon from "../Assets/Icons/helpicon.svg";
import BillingHistory from "../Assets/Icons/billinghistory.svg";
import Subscription from "../Assets/Icons/subscriptionicon.svg";
import ContactIcon from "../Assets/Icons/contacticon.svg";
import Collapse from "@mui/material/Collapse";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Switch from "@mui/material/Switch";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Logo from "../Components/Logo";
import SidebarImg from "../Assets/Images/sidebarimg.png";
import NotificationIcon from "../Assets/Icons/notification.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import AccountIcon from "../Assets/Icons/accounticon.svg";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { Link } from "react-router-dom";
import Contactusicon from "../Assets/Icons/contactusicon.svg";
import { useTranslation } from "react-i18next";
import SelectLanguage from "../Components/LanguageSelect";
import Popover from "@mui/material/Popover";
import Cookie from "js-cookie";
import { useSelector, useDispatch } from "react-redux";
import { setUserData, updateUser } from "../Redux/Slices/userSlice";
import Announcement from "../Assets/Icons/Announcement.png";
import GoogleLogin from "react-google-login";
import {
  incrementNotifications,
  updateNotifications,
  updateNotificationscount,
} from "../Redux/Slices/notificationsSlice";
import { width } from "@mui/system";
import {
  updateInvitations,
  updateInvitationsBackup,
} from "../Redux/Slices/InvitationsSlice";
import { updateBackupOrders, updateOrders } from "../Redux/Slices/orderSlice";
import { updateCalendarEmails } from "../Redux/Slices/selectedCalendarEmails";
import toast from "react-hot-toast";
import UnavailableModal from "../Pages/DashboardScreens/UnavailableModal";
import { postRequest } from "../Requests/Requests";
import { setSteppers } from "../Redux/Slices/stepperInfoSlice";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;
const backendpoint = process.env.REACT_APP_GOSIGNING_BACKEND_ENDPOINT;

const drawerWidth = 275;

function DashboardLayout({ children }) {
  const [t, i18n] = useTranslation("global");
  const user = useSelector((state) => state.user.user);
  const profileneedcompletion =
    useSelector((state) => state.user.profile_image_url) == "" ? true : false;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const notificationcount = useSelector(
    (state) => state.notifications.notifications.count
  );
  const notifications = useSelector(
    (state) => state.notifications.notifications.data
  );
  const subscription = useSelector((state) => state.user.subscription);

  const MainMenu = [
    { id: 1, index: t("dashboardlayout.dashboard"), path: "/Dashboard" },
    { id: 2, index: t("dashboardlayout.inbox"), path: "/inbox" },
    {
      id: 3,
      index: t("dashboardlayout.setting"),
      submenus: [
        {
          id: 1,
          label: t("dashboardlayout.account"),
          icon: AccountIcons,
          path: "/account",
        },
        {
          id: 2,
          label: t("dashboardlayout.preffrence"),
          icon: RefrenceIcon,
          path: "/prefrence",
        },
        {
          id: 3,
          label: t("settingsidebar.voip"),
          icon: RefrenceIcon,
          path: "/voip",
        },
        {
          id: 4,
          label: t("dashboardlayout.subscription"),
          icon: Subscription,
          path: "/subscription",
        },
        {
          id: 5,
          label: t("dashboardlayout.billing"),
          icon: BillingHistory,
          path: "/billing",
        },
      ],
    },
  ];

  const SecondaryMenu = [
    { id: 4, index: t("dashboardlayout.help"), path: "/helpcenter" },
    { id: 5, index: t("dashboardlayout.contact"), path: "/contactus" },
  ];

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlelogout = async () => {
    const form_data = new FormData();
    form_data.append("email", user?.email);
    postRequest("/logout", form_data)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        if (result?.data?.code == 1) {
          sessionStorage.setItem("signedin", null);
          Cookie.set("remembermetoken", null);
          Cookie.set("access_token", null);
          navigate("/signin");
        } else {
        }
      });
  };

  const invitationsBackup = useSelector(
    (state) => state.invitations.invitationsBackup
  );

  const [anchorE2, setAnchorE2] = useState(null);

  const handleClick = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorE2(null);
  };

  useEffect(() => {
    if (user?.subscription_status == "active") {
      const form_data = new FormData();
      form_data.append("email", user?.email);
      postRequest("/dashboard/data", form_data).then((result) => {
        if (!result?.error) {
          dispatch(
            setUserData({
              subscription: result?.data.subscription,
              user: result?.data.user,
              calendar_accounts: result?.data.calendar_accounts,
            })
          );
          dispatch(updateNotifications(result?.data?.notifications));
          const unreadNotifications = notifications?.filter(
            (notification) => notification?.read_at === null
          );
          dispatch(updateNotificationscount(unreadNotifications?.length));
          // dispatch(updateInvitations(result?.data?.invitations));
          dispatch(updateInvitationsBackup(result?.data?.invitations));
          dispatch(updateCalendarEmails([result?.data?.user?.email]));
          dispatch(setSteppers(result?.data?.stepperInfo));
        }
      });
    } else {
      // console.log("I am Inactive");
    }
  }, [dispatch, invitationsBackup]);

  useEffect(() => {
    if (user?.subscription_status == "active") {
      const form_data = new FormData();
      form_data.append("email", Cookie.get("user_email"));

      postRequest("/Orders/data", form_data).then((result) => {
        // console.log(result);
        // dispatch(updateOrders(result?.orders));
        dispatch(updateBackupOrders(result?.orders));
      });
    } else {
      // console.log("I am Inactive");
    }
  }, [invitationsBackup]);

  const open = Boolean(anchorE2);
  const id = open ? "notification-popover" : undefined;

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      // sx={{top:"65px"}}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      disableScrollLock
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Link to={"/account"} style={{ textDecoration: "none", color: "black" }}>
        <MenuItem onClick={handleMenuClose}>
          {t("dashboardlayout.setting")}
        </MenuItem>
      </Link>
      <Link to={"/Signin"} style={{ textDecoration: "none", color: "black" }}>
        <MenuItem onClick={handlelogout}>
          {t("dashboardlayout.logout")}
        </MenuItem>
      </Link>
    </Menu>
  );

  const [mobileOpen, setMobileOpen] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const toggleMenu = (index) => {
    setOpenSubMenu(openSubMenu === index ? null : index);
  };

  const drawerContent = (
    <div>
      {!subscription && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(128, 128, 128, 0.5)",
            zIndex: 1,
          }}
        />
      )}
      <Logo />
      <Divider />
      <List sx={{ px: 2 }}>
        {MainMenu.map((item, index) => (
          <div key={item.id}>
            <NavLink
              to={item.path}
              style={{ color: "black", textDecoration: "none" }}
            >
              <ListItem
                sx={{
                  color: "black",
                  borderRadius: "2px 6px 6px 2px",
                  "&:hover": { backgroundColor: "#FADCC1" },
                  "&:active, &.Mui-selected": {
                    borderLeft: "4px solid #F26F21",
                    backgroundColor: "#FADCC1",
                  },
                }}
                onClick={() => item.submenus && toggleMenu(index)}
                button
                selected={window.location.pathname === item.path}
              >
                <ListItemIcon>
                  {index === 0 ? (
                    <img src={DashboardIcon} alt="Dashboard" />
                  ) : index === 1 ? (
                    <img src={InboxIcon} alt="Inbox" />
                  ) : (
                    <img src={SettingIcon} alt="Setting" />
                  )}
                </ListItemIcon>
                <ListItemText primary={item.index} />
                {item.submenus &&
                  (openSubMenu === index ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  ))}
              </ListItem>
            </NavLink>
            {item.submenus && (
              <Collapse in={openSubMenu === index} timeout="auto" unmountOnExit>
                <List component="div" disablePadding sx={{ pl: 1 }}>
                  {item.submenus.map((submenu) => (
                    <Link
                      to={submenu.path}
                      key={submenu.id}
                      style={{ color: "black", textDecoration: "none" }}
                    >
                      <ListItem
                        button
                        key={submenu.id}
                        sx={{
                          borderRadius: "2px 6px 6px 2px",
                          "&:hover": { backgroundColor: "#FADCC1" },
                          "&:active, &.Mui-selected": {
                            borderLeft: "4px solid #F26F21",
                            backgroundColor: "#FADCC1",
                          },
                        }}
                        selected={window.location.pathname === submenu.path}
                      >
                        <ListItemIcon>
                          <img src={submenu.icon} alt={submenu.label} />
                        </ListItemIcon>
                        <ListItemText primary={submenu.label} />
                      </ListItem>
                    </Link>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}
      </List>
      <Divider sx={{ mx: 4 }} />
      <List sx={{ px: 2 }}>
        {SecondaryMenu.map((item) => (
          <ListItem
            button
            key={item.id}
            component="a"
            href={item.path}
            sx={{
              borderRadius: "2px 6px 6px 2px",
              "&:hover": { backgroundColor: "#FADCC1" }, // Hover effect
              "&:active, &.Mui-selected": {
                borderLeft: "4px solid #F26F21", // Left border with red color
                backgroundColor: "#FADCC1", // Slightly darker background color when active
              },
            }}
            selected={window.location.pathname === item.path}
          >
            <ListItemIcon>
              <img
                src={item.id === 4 ? HelpIcon : Contactusicon}
                alt={item.index}
              />
            </ListItemIcon>
            <ListItemText primary={item.index} />
          </ListItem>
        ))}
      </List>
      <Divider />

      <Grid
        container
        sx={{
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Ensure the Grid takes up the full height of its container
        }}
      >
        <Grid item>
          <img
            // src={SidebarImg}
            src={`${backendpoint}${user?.profile_image_url}`}
            alt="set your image"
            style={{
              width: "200px",
              height: "200px",
              objectFit: "cover",
              marginTop: 5,
              borderRadius: "50%",
            }}
          />
        </Grid>
      </Grid>
      {user?.default_invitaion_settings == 1 && (
        <Typography
          sx={{
            textAlign: "center",
            color: "#F26F21",
            m: 3.5,
            fontSize: "14px",
          }}
        >
          {t("dashboardlayout.defaulyinvitation")}
        </Typography>
      )}

      {profileneedcompletion && (
        <Link to="/account" style={{ textDecoration: "none", color: "black" }}>
          <Box sx={{ display: "flex", m: 3.5, textAlign: "center" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="23"
              viewBox="0 0 27 23"
              fill="none"
            >
              <circle
                cx="15.4346"
                cy="11.5"
                r="10.8947"
                stroke="#F26F21"
                strokeWidth="1.21053"
              />
              <path
                d="M15.8622 11.3748C16.0986 11.6111 16.0986 11.9944 15.8622 12.2307L12.0103 16.0826C11.774 16.319 11.3907 16.319 11.1544 16.0826C10.918 15.8462 10.918 15.463 11.1544 15.2266L14.5782 11.8027L11.1544 8.37885C10.918 8.14248 10.918 7.75925 11.1544 7.52288C11.3907 7.28651 11.774 7.28651 12.0103 7.52288L15.8622 11.3748ZM5.29138e-08 11.1975L15.4342 11.1975L15.4342 12.408L-5.29138e-08 12.408L5.29138e-08 11.1975Z"
                fill="#6A9B41"
              />
            </svg>
            <Typography
              sx={{
                color: "#F26F21",
                fontSize: "14px",
                mx: "5px",
                textDecoration: "underline",
                fontWeight: 700,
              }}
            >
              {t("dashboardlayout.finish_setup")}
            </Typography>
          </Box>
        </Link>
      )}
      <Grid item xs={12} sx={{ m: 2.5 }}>
        {subscription && subscription?.days && (
          <Button
            sx={{
              width: "100%",
              py: "10px",
              px: "6px",
              borderRadius: "15px",
              fontWeight: 500,
              fontSize: {
                xs: "12px",
                md: "16px",
              },
              fontWeight: 600,
              "&:hover": {
                backgroundColor: "white",
                color: "#6A9B41",
                boxShadow: "none",
                cursor: "default",
              },
              backgroundColor: "white",
              color: "#6A9B41",
              boxShadow: "none",
              border: "1px solid #C5C5C5",
              textTransform: "none", // Add this line to set text to normal case
              pointerEvents: "none",
            }}
            size="large"
            variant="contained"
            lg={12}
          >
            {`${
              subscription?.months != 0 ? subscription?.months + " months" : ""
            }   ${subscription?.days} Days remaining of ${
              subscription?.plan
            } plan `}
            {/* {trailDays} {t("dashboardlayout.free_trail")} */}
          </Button>
        )}
      </Grid>
    </div>
  );

  const [iamuopen, setiamuopen] = useState(false);

  const toggleIamavailable = async (date = null) => {
    setiamav(!iamav);
    setloading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("id", user?.id);
    form_data.append("email", user?.email);
    if (date) {
      form_data.append("date", date);
    }
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
    };
    fetch(`${apiEndpoint}/toggle/Iamavilable`, requestOptions)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) => {
        // console.log(result);
        if (result?.error) {
          toast.error(result?.error);
          setloading(false);
        } else {
          dispatch(updateUser(result?.user));
        }
        setloading(false);
      })
      .catch((err) => {
        toast.error(err);
        setloading(false);
      });
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  let [iamav, setiamav] = useState(user?.Iamavilable);
  const [loading, setloading] = useState(false);
  const [toMarkNotification, setToMarkNotification] = useState(null);

  const marknotificationasread = async (item) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );
      myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
      const form_data = new FormData();
      form_data.append("email", user?.email);
      form_data.append("id", item?.id);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: form_data,
      };
      fetch(`${apiEndpoint}/notification/markasread`, requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          }
        })
        .then((result) => {
          setToMarkNotification(null);
          dispatch(updateNotifications(result?.notifications));
          const unreadNotifications = result?.notifications?.filter(
            (notification) => notification.read_at === null
          );
          dispatch(updateNotificationscount(unreadNotifications?.length));
        })
        .catch((err) => {
          console.error(err);
          setToMarkNotification(null);
        });
    } catch (e) {
      console.log(e);
      setToMarkNotification(null);
    }
  };

  const handleIamavailable = (result, date = null) => {
    if (date) {
      toggleIamavailable(date?.$d);
    }
    setiamuopen(false);
  };

  return (
    <>
      <Typography>
        ghguhhiuuhelo hi i sm dh fighf iiy7 wesoiytr dfc iuisyg kjs ndg oifdusgn
        idrjg lsu89tg{" "}
      </Typography>

      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar
          position="fixed"
          sx={{
            boxShadow: "none",
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
        >
          <Toolbar
            sx={{
              height: "107px",
              backgroundColor: "white",
              borderBottom: "1px solid #DBDBDB;",
            }}
          >
            {!subscription && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: "rgba(128, 128, 128, 0.5)",
                  zIndex: 1,
                }}
              />
            )}

            <IconButton
              color="black"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{ display: "flex", marginLeft: "auto", alignItems: "center" }}
            >
              <IconButton
                // size="large"

                aria-label="show 4 new notificatons"
                color="inherit"
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent", // Set the background color to transparent on hover
                  },
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                {loading && ( // Conditional rendering of loader
                  <Box
                    sx={{
                      zIndex: 3,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      backgroundColor: "transparent",
                      transform: "translate(-50%, -50%)",
                      top: "50%",
                      left: "50%",
                      opacity: 0.8,
                      width: { lg: "100%", xs: "100%" },
                      height: { lg: "100%", xs: "100%" },
                    }}
                  >
                    <CircularProgress sx={{ color: "#F26F21" }} size={20} />
                  </Box>
                )}

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography variant="body2" sx={{ color: "black" }}>
                    {t("dashboardlayout.Im_available")}
                  </Typography>

                  <Switch
                    checked={user?.Iamavilable}
                    onChange={() => {
                      if (user?.Iamavilable) {
                        setiamuopen(true);
                      } else {
                        toggleIamavailable();
                      }
                    }}
                    color="primary"
                    sx={{
                      "& .MuiSwitch-thumb": {
                        backgroundColor: "#6A9B41",
                        width: 12, // Adjust the width of the thumb
                        height: 12, // Adjust the height of the thumb
                        marginTop: 0.5, // Adjust vertical position to center it with the track
                        marginLeft: 0.5, // Adjust horizontal position to center it with the track
                      },
                      "& .MuiSwitch-thumb.Mui-checked": {
                        backgroundColor: "white", // Background color when switch is on
                      },
                      "& .MuiSwitch-track": {
                        backgroundColor: "white",
                        border: "1px solid grey", // Border around the track
                      },
                    }}
                  />
                </Box>
                {!user?.Iamavilable && (
                  <Box>
                    {user?.unavailable_till && (
                      <Typography sx={{ color: "red", fontSize: "12px" }}>
                        Till {String(user?.unavailable_till)}
                      </Typography>
                    )}
                  </Box>
                )}
              </IconButton>
              <IconButton
                // size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <Badge badgeContent={notificationcount} color="error">
                  <img
                    src={NotificationIcon}
                    alt="Notification Icon"
                    onClick={handleClick}
                  />
                </Badge>
                {true && (
                  <Popover
                    open={open}
                    anchorEl={anchorE2} // Note: It should be anchorEl, not anchorE2
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right", // Adjusted to "left" to align with the icon's end
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right", // Adjusted to "left" to align with the icon's end
                    }}
                    disableScrollLock
                    PaperProps={{
                      sx: {
                        width: { lg: "30%", xs: "70%" }, // Adjust width as needed
                        maxHeight: "50%", // Adjust maxHeight as needed
                        borderRadius: "10px",
                        "&::-webkit-scrollbar": {
                          width: "8px", // Width of the scrollbar
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: "#F4933A", // Color of the thumb
                          borderRadius: "4px", // Rounded corners for the thumb
                          height: "30px",
                        },
                        "&::-webkit-scrollbar-track": {
                          backgroundColor: "#D6EBCC", // Color of the track
                        },
                      },
                    }}
                  >
                    <Box sx={{ p: 2, borderRadius: "10px" }}>
                      {notifications?.length > 0 &&
                        notifications.map((item, index) => {
                          if (item) {
                            return (
                              <>
                                <Box
                                  sx={{
                                    p: 2,
                                    borderBottom: "1px solid #ECECEC",
                                  }}
                                >
                                  <Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      {item.read_at == null && (
                                        <div
                                          style={{
                                            width: "10px",
                                            height: "10px",
                                            borderRadius: "50%",
                                            backgroundColor: "#F4933A",
                                            marginRight: "5px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        />
                                      )}
                                      <Typography
                                        sx={{
                                          fontSize: "15px",
                                          fontWeight: 900,
                                        }}
                                      >
                                        {item?.type}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      sx={{
                                        fontSize: { lg: "12px", xs: "10px" },
                                      }}
                                    >
                                      {item?.data}
                                    </Typography>
                                  </Box>
                                  <Typography
                                    sx={{
                                      color: "#B4B9C1",
                                      whiteSpace: "nowrap",
                                      fontSize: { lg: "12px", xs: "10px" },
                                    }}
                                  >
                                    {dayjs(item.created_at).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      cursor: "pointer",
                                      color: "black",
                                    }}
                                  >
                                    {item.read_at == null && (
                                      <Typography
                                        sx={{
                                          color: "#B4B9C1",
                                          whiteSpace: "nowrap",
                                          fontSize: { lg: "12px", xs: "10px" },
                                        }}
                                        onClick={() => {
                                          setToMarkNotification(item.id);
                                          marknotificationasread(item);
                                        }}
                                      >
                                        mark as read
                                      </Typography>
                                    )}
                                    {item.id == toMarkNotification && (
                                      <Box
                                        sx={{
                                          zIndex: 3,
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          position: "absolute",
                                          backgroundColor: "transparent",
                                          transform: "translate(-50%, -50%)",
                                          top: "50%",
                                          left: "50%",
                                          opacity: 0.8,
                                          width: { lg: "100%", xs: "100%" },
                                          height: { lg: "100%", xs: "100%" },
                                        }}
                                      >
                                        <CircularProgress
                                          sx={{ color: "#F26F21" }}
                                          size={30}
                                        />
                                      </Box>
                                    )}
                                  </Box>
                                </Box>
                              </>
                            );
                          }
                        })}
                    </Box>
                  </Popover>
                )}
              </IconButton>
              {/* select lang */}
              <SelectLanguage dropdownDirection="down" />
              <IconButton
                // size="large"
                edge="end"
                aria-label="account of the current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  my: "auto",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
              >
                <img
                  src={`${process.env.REACT_APP_GOSIGNING_BACKEND_ENDPOINT}${user?.profile_image_url}`}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                    my: "auto",
                  }}
                  style={{
                    width: "50px",
                    borderRadius: "25px",
                    height: "50px",
                    objectFit: "cover",
                  }}
                />
                <ArrowDropDownIcon />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMenu}
        <Box
          component="nav"
          className="sidebar scroll"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawerContent}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawerContent}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: { lg: 3, xs: 1 },
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar sx={{ my: 3 }} />
          {children}
        </Box>
        <UnavailableModal
          open={iamuopen}
          handleClose={handleIamavailable}
        ></UnavailableModal>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  window: PropTypes.func,
};

export default DashboardLayout;
