import { Box } from "@mui/system";
import Logo from "../Assets/Images/bllogo.png";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie"; // Assuming you're using cookies to manage the login state

export default function SiteLogo({ sx }) {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    const isLoggedIn = sessionStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
      navigate("/dashboard"); // Redirect to dashboard if logged in
    } else {
      navigate("/Signup"); // Redirect to landing page or signup page if not logged in
    }
  };
  

  return (
    <>
      <Box
        sx={{ display: { lg: "block", xs: "none" } }}
        onClick={handleLogoClick}
        style={{ cursor: "pointer" }} // Add cursor pointer to indicate clickable element
      >
        <img
          src={Logo}
          alt="logo"
          style={{
            position: "relative",
            top: "-3%",
            width: "218px",
            height: "99px",
            maxWidth: "100%",
            height: "auto",
            objectFit: "cover",
          }}
        />
      </Box>
      <Box
        sx={{ display: { lg: "none", xs: "block" } }}
        onClick={handleLogoClick}
        style={{ cursor: "pointer" }} // Add cursor pointer to indicate clickable element
      >
        <img
          src={Logo}
          alt="logo"
          style={{
            position: "relative",
            top: "-0%",
            width: "180px",
            height: "46px",
            maxWidth: "100%",
            height: "auto",
            objectFit: "cover",
          }}
        />
      </Box>
    </>
  );
}
