import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import languageReducer from "../Redux/Slices/languageSlice";
import userReducer from "../Redux/Slices/userSlice";
import notificationsSlice from "./Slices/notificationsSlice";
import eventsReducer from "./Slices/events";
import InvitationsSlice from "./Slices/InvitationsSlice";
import preferenceSlice from "./Slices/preferenceSlice";
import orderSlice from "./Slices/orderSlice";
import selectedCalendarEmails from "./Slices/selectedCalendarEmails";
import calendarSlice from "./Slices/calendarSlice";
import messagesSlice from "./Slices/messagesSlice";
import stepperInfoSlice from "./Slices/stepperInfoSlice";

// Combine all your reducers into a rootReducer
const rootReducer = combineReducers({
  language: languageReducer,
  selectedCalendarEmails: selectedCalendarEmails,
  user: userReducer,
  notifications: notificationsSlice,
  events: eventsReducer,
  invitations: InvitationsSlice,
  preferences: preferenceSlice,
  orders: orderSlice,
  calenderEvents: calendarSlice,
  messages: messagesSlice,
  stepperInfo: stepperInfoSlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

// Persist the rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Needed for redux-persist to work properly
    }),
});

const persistor = persistStore(store);

export { store, persistor };
