import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import Button from '../Components/Button';
import { Link } from 'react-router-dom';

const Card = ({  heading,  text,  height,  name,  sxb,  sxh,  sxt,  backgroundColor,  flexDirection,   flexWrap, }) => {
  return (
    <Box
      sx={{
        padding: { xs: '50px 0px', md: '100px 0px' }, // Responsive padding
        backgroundColor: backgroundColor || '#fff', // Dynamic backgroundColor
      }}
    >
      <Container
        sx={{
          width: '100%', // Full width for responsiveness
          height: height || 'auto', // Customizable height
          background: 'linear-gradient(135deg, #6a9b41, #b8b16f)', // Gradient background
          borderRadius: '8px', // Rounded corners
          padding: { xs: '10px', md: '20px' }, // Responsive padding
          boxShadow: '0 4px 8px rgba(0,0,0,0.1)', // Shadow for 3D effect
          textAlign: 'center', // Centered text
          display: 'flex',
          flexDirection: flexDirection || 'column', // Dynamic flexDirection
          flexWrap: flexWrap || 'nowrap', // Dynamic flexWrap
          justifyContent: 'center',
          alignItems: 'center',
          color: '#fff', // Text color

          '@media (max-width: 600px)': {
            padding: '15px', // Adjust padding for smaller screens
          },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            ...sxh,
           
          }}
        >
          {heading}
        </Typography>

        <Typography
          sx={{
            ...sxt,
            fontSize: { xs: '14px', md: '18px' }, // Responsive font size for text
            marginBottom: { xs: '16px', md: '20px' }, // Adjust margins
          }}
        >
          {text}
        </Typography>

        <Link to="/Signup" style={{ textDecoration: 'none' }}>
          <Button
            btnname={name}
            variant="contained"
            className="Orangebtn"
            sx={{
              ...sxb,
              padding: { xs: '8px 16px', md: '10px 20px' }, // Adjust button size
              fontSize: { xs: '12px', md: '16px' }, // Responsive font size for button
            }}
          />
        </Link>
      </Container>
    </Box>
  );
};

export default Card;
