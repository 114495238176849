import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button } from "@mui/material";
// import Button from "../../Components/Button";
const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;
const REACT_APP_GOSIGNING_FRONTEND = process.env.REACT_APP_GOSIGNING_FRONTEND;

export default function Yahoo({
  setLoading,
  authFlowType,
  setCalenderAccountAdded,
  onEmailSelect,
}) {
  const navigate = useNavigate();
  // Function to generate a random code verifier (43 to 128 characters)
  function generateCodeVerifier() {
    const randomArray = new Uint8Array(32); // 32 bytes generates a string of ~43 characters
    window.crypto.getRandomValues(randomArray);
    return base64URLEncode(randomArray);
  }

  // Function to hash the code verifier using SHA-256 and then base64 encode it
  async function generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    return base64URLEncode(new Uint8Array(hashBuffer));
  }

  // Function to URL-safe Base64 encode a string
  function base64URLEncode(arrayBuffer) {
    return btoa(String.fromCharCode.apply(null, arrayBuffer))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }
  // Yahoo Client ID and Redirect URI
  const clientId =
    "dj0yJmk9NnY5ZnhUcUw0aGF0JmQ9WVdrOWJYSm5ZMDVDV200bWNHbzlNQT09JnM9Y29uc3VtZXJzZWNyZXQmc3Y9MCZ4PWUx"; // Replace with your Yahoo client ID
  const redirectUri = "https://test.gosignings.com/yahoo/callback"; // Replace with your redirect URI
  const scopes = "openid email profile";

  const handleYahooLogin = async () => {
    const verifier = generateCodeVerifier();
    const challenge = await generateCodeChallenge(verifier);
    localStorage.setItem("code_verifier", verifier);

    const authUrl = `https://api.login.yahoo.com/oauth2/request_auth?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_type=code&scope=${encodeURIComponent(
      scopes
    )}&code_challenge=${challenge}&code_challenge_method=S256`;

    const authWindow = window.open(authUrl, "_blank", "width=500,height=600");

    const interval = setInterval(() => {
      try {
        if (authWindow.location.href.includes(REACT_APP_GOSIGNING_FRONTEND)) {
          clearInterval(interval);
          const urlParams = new URLSearchParams(authWindow.location.search);
          const authorizationCode = urlParams.get("code");
          console.log(authorizationCode);
          console.log(verifier);
          if (authorizationCode && verifier) {
            getToken(authorizationCode, verifier);
          }
          authWindow.close();
        }
      } catch (error) {
        console.log(error);
        // Handle cross-origin errors gracefully
      }
    }, 1000);
  };

  const getToken = async (code, codeVerifier) => {
    try {
      const headers = new Headers();
      headers.append(
        process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );

      const formData = new FormData();
      formData.append("code", code);
      formData.append("code_verifier", codeVerifier);

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: formData,
      };

      const response = await fetch(
        `${apiEndpoint}/yahoo/token`,
        requestOptions
      );
      const tokens = response.json();
      console.log(tokens);
      if (tokens) {
        const { access_token, refresh_token } = tokens;
        getUserInfo(access_token, refresh_token);
      }
    } catch (error) {
      console.error("Error fetching Yahoo token", error);
    }
  };

  // const getToken = async (code, codeVerifier) => {
  //   const tokenUrl = "https://api.login.yahoo.com/oauth2/get_token";
  //   const body = new URLSearchParams({
  //     client_id: clientId,
  //     client_secret: "13aca49bb32f11ea41e36e208433abdbb359776a", // Replace with your Yahoo client secret
  //     code: code,
  //     redirect_uri: redirectUri,
  //     grant_type: "authorization_code",
  //     code_verifier: codeVerifier,
  //   });

  //   try {
  //     const response = await axios.post(tokenUrl, body, {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //     });
  //     const tokens = response.data;
  //     console.log(tokens);
  //     if (tokens) {
  //       const { access_token, refresh_token, expires_in } = tokens;
  //       getUserInfo(access_token, refresh_token);
  //     }
  //   } catch (error) {
  //     return null;
  //   }
  // };

  const getUserInfo = async (accessToken, refresh_token) => {
    try {
      const response = await axios.get(
        "https://api.login.yahoo.com/openid/v1/userinfo",
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const userinfo = response.data;
      console.log(userinfo);
      localStorage.setItem("userinfo", JSON.stringify(userinfo));
      localStorage.setItem("refresh_token", refresh_token);

      if (authFlowType == "signin") {
        navigate("/Signin");
      } else if (authFlowType == "register") {
        navigate("/SignUp");
      } else if (authFlowType == "calendar") {
        // Add calendar account
        onEmailSelect(userinfo?.email, refresh_token);
        // Logic for adding the calendar account goes here
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    localStorage.setItem("authFlowType", authFlowType);
  }, []);

  return (
    // <Typography
    //   onClick={handleYahooLogin}
    //   sx={{
    //     fontSize: "14px",
    //     color: "#F26F21",
    //     display: "flex",
    //     alignItems: "center",
    //     cursor: "pointer",
    //   }}
    // >
    //   Signin with yahoo
    // </Typography>
    <>
      <Button
        className="greybtn"
        onClick={handleYahooLogin}
        sx={{
          width: "100%",
          py: "10px",
          fontSize: { xs: "12px", md: "20px" },
          borderRadius: "8px",
        }}
        size="large"
        variant="contained"
        // startIcon={
        //   <img
        //     src={GoogleIcon}
        //     alt="Google Icon"
        //     style={{ width: "28px", height: "28px" }}
        //   />
        // }
      >
        Register with Yahoo
      </Button>
    </>
  );
}
