import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, Typography } from "@mui/material";

const NumberingSlider = ({ slideData }) => {
  const settings = {
    dots: false,
    infinite: false, // Stop infinite scrolling on desktop by default
    speed: 500,
    slidesToShow: 6, // 6 slides for desktop
    slidesToScroll: 1,
    autoplay: false, // Disable autoplay for desktop by default
    autoplaySpeed: 3000,
    arrows: false,
    responsive: [
      {
        breakpoint: 992, // tablet breakpoint
        settings: {
          slidesToShow: 3, // Show 3 slides on tablet
          infinite: true, // Stop slider on tablet as well
          autoplay: true, // Stop autoplay on tablet
        },
      },
      {
        breakpoint: 576, // mobile breakpoint
        settings: {
          slidesToShow: 1, // Show 1 slide on mobile
          infinite: true, // Enable infinite loop on mobile
          autoplay: true, // Enable autoplay on mobile
        },
      },
    ],
  };
  

  return (
    <Slider {...settings}>
      {slideData.map((slide, index) => (
        <div key={index}>
          <CustomSlide
            number={slide.number}
            heading={slide.heading}
          />
        </div>
      ))}
    </Slider>
  );
};

const CustomSlide = ({ number, heading }) => {
  return (
    <Box sx={{ textAlign: "center", position: "relative", margin: '20px 20px' }}>
      <Box
        sx={{
          width: "60px",
          height: "60px",
          borderRadius: "50%",
          backgroundColor: "#ef6e21",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Typography
          sx={{ color: "white", fontSize: "24px", fontWeight: "bold" }}
        >
          {number}
        </Typography>
      </Box>
      <Typography sx={{ marginTop: "10px", color: "#000000", fontSize: "16px" }}>
        {heading}
      </Typography>

      {/* Connector line for all except the last slide */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          right: "-50%",
          height: "2px",
          backgroundColor: "#2A3D1D",
          width: "100%",
          zIndex: -1,
        }}
      />
    </Box>
  );
};

export default NumberingSlider;
