import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { postRequest } from "../../Requests/Requests";
import { Typography } from "@mui/material";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;
const REACT_APP_GOSIGNING_FRONTEND = process.env.REACT_APP_GOSIGNING_FRONTEND;

export default function Outlook({
  setLoading,
  authFlowType,
  setCalenderAccountAdded,
  onEmailSelect,
  setFormData,
  formData,
  index,
  activity,
}) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user?.user);

  // Function to generate a random code verifier (43 to 128 characters)
  function generateCodeVerifier() {
    const randomArray = new Uint8Array(32); // 32 bytes generates a string of ~43 characters
    window.crypto.getRandomValues(randomArray);
    return base64URLEncode(randomArray);
  }

  // Function to hash the code verifier using SHA-256 and then base64 encode it
  async function generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder();
    const data = encoder.encode(codeVerifier);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    return base64URLEncode(new Uint8Array(hashBuffer));
  }

  // Function to URL-safe Base64 encode a string
  function base64URLEncode(arrayBuffer) {
    return btoa(String.fromCharCode.apply(null, arrayBuffer))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");
  }
  const clientId = "e300ce34-2d3a-45ea-afba-ff68965a608f";
  const redirectUri = "http://localhost:3000/outlook/callback"; // Redirect URL should match what you set in Azure Portal
  const scopes =
    "openid profile email Mail.Read Mail.Send User.Read Calendars.Read Calendars.ReadBasic Calendars.ReadWrite";

  // const handleOutlookLogin = async () => {
  //   const verifier = generateCodeVerifier();
  //   const challenge = await generateCodeChallenge(verifier);
  //   localStorage.setItem("code_verifier", verifier);

  //   const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(
  //     redirectUri
  //   )}&response_mode=query&scope=${encodeURIComponent(
  //     scopes
  //   )}&code_challenge=${challenge}&code_challenge_method=S256`;

  //   const authWindow = window.open(authUrl, "_blank", "width=500,height=600");

  //   const interval = setInterval(() => {
  //     try {
  //       if (
  //         // authWindow.location.href.includes(REACT_APP_GOSIGNING_FRONTEND) ||
  //         authWindow.location.href.includes("http://localhost:3000")
  //       ) {
  //         clearInterval(interval);
  //         const urlParams = new URLSearchParams(authWindow.location.search);
  //         const authorizationCode = urlParams.get("code");
  //         console.log("authorizationCode", authorizationCode);
  //         //   const codeVerifier = localStorage.getItem("code_verifier");
  //         if (authorizationCode && verifier) {
  //           getToken(authorizationCode, verifier);
  //         }
  //         authWindow.close();
  //       }
  //     } catch (error) {
  //       console.log(error);
  //       // Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
  //     }
  //   }, 1000);
  // };

  const handleOutlookLogin = async () => {
    const verifier = generateCodeVerifier();
    const challenge = await generateCodeChallenge(verifier);
    localStorage.setItem("code_verifier", verifier);

    const authUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&response_mode=query&scope=${encodeURIComponent(
      scopes
    )}&code_challenge=${challenge}&code_challenge_method=S256`;

    const authWindow = window.open(authUrl, "_blank", "width=500,height=600");

    window.addEventListener("message", (event) => {
      // console.log(event);
      // if (event.origin !== window.location.origin) {
      //   return;
      // }

      if (event.origin == "http://localhost:3000") {
        if (event.data.type === "oauth_callback" && event.data.code) {
          const authorizationCode = event.data.code;
          console.log("authorizationCode", authorizationCode);
          const codeVerifier = localStorage.getItem("code_verifier");
          if (authorizationCode && codeVerifier) {
            getToken(authorizationCode, codeVerifier);
          }
        }
      }
    });
  };

  const getToken = async (code, codeVerifier) => {
    const tokenUrl =
      "https://login.microsoftonline.com/common/oauth2/v2.0/token";
    const body = new URLSearchParams({
      client_id: clientId,
      scope:
        "openid profile email Mail.Read Mail.Send Calendars.Read Calendars.ReadBasic Calendars.ReadWrite",
      code: code,
      redirect_uri: redirectUri,
      grant_type: "authorization_code",
      code_verifier: codeVerifier,
    });

    try {
      const response = await axios.post(tokenUrl, body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      });
      const tokens = response.data;
      if (tokens) {
        const { access_token, refresh_token, expires_in } = tokens;
        getUserInfo(access_token, refresh_token);
      }
    } catch (error) {
      return null;
    }
  };
  const getUserInfo = async (accessToken, refresh_token) => {
    try {
      const response = await axios.get("https://graph.microsoft.com/v1.0/me", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const userinfo = response.data;
      localStorage.setItem("userinfo", JSON.stringify(userinfo));
      localStorage.setItem("refresh_token", refresh_token);

      if (authFlowType == "signin") {
        navigate("/Signin");
      } else if (authFlowType == "register") {
        navigate("/SignUp");
      } else if (authFlowType == "calendar") {
        if (activity == "mail") {
          let mailAccounts = [...formData.mail_accounts];
          mailAccounts[index] = {
            ...mailAccounts[index],
            account_type: "outlook",
            account_email: userinfo?.mail,
            refresh_token: refresh_token,
          };

          setFormData((prevData) => ({
            ...prevData,
            mail_accounts: mailAccounts,
          }));
        } else if (activity == "calendar") {
          onEmailSelect(userinfo?.mail, refresh_token);
        }
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    localStorage.setItem("authFlowType", authFlowType);
  }, []);

  return (
    <>
      {activity == "calendar" && (
        <Typography
          // className="greybtn"
          onClick={handleOutlookLogin}
          // sx={{
          //   width: "100%",
          //   py: "10px",
          //   fontSize: { xs: "12px", md: "20px" },
          //   borderRadius: "8px",
          // }}
          // size="large"
          // variant="contained"
          // startIcon={
          //   <img
          //     src={GoogleIcon}
          //     alt="Google Icon"
          //     style={{ width: "28px", height: "28px" }}
          //   />
          // }
          sx={{
            fontSize: "14px",
            color: "#F26F21",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          Add
        </Typography>
      )}

      {activity == "mail" && (
        <MicrosoftIcon
          onClick={handleOutlookLogin}
          sx={{ cursor: "pointer", ml: 2 }}
        />
      )}
    </>
  );
}
