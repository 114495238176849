import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  Container,
  Button,
  CircularProgress,
} from "@mui/material";
import DashboardLayout from "../../Layouts/DashboardLayout";
import SettingSidebar from "../../Components/SettingSidebar";
import CustomField from "../../Components/CustomField";
import Buttons from "../../Components/Button";
import photo from "../../Assets/Images/photoplaceholder.png";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import Cookie from "js-cookie";
import { updateOnlyUser, updateUser } from "../../Redux/Slices/userSlice";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;
const backendpoint = process.env.REACT_APP_GOSIGNING_BACKEND_ENDPOINT;

const Setting = () => {
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  let user = useSelector((state) => state.user.user);

  // console.log(useSelector((state) => state.user));

  const [selectedFile, setSelectedFile] = useState(photo);
  const [fname, setFname] = useState(user?.first_name || "");
  const [lname, setLname] = useState(user?.last_name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [pno, setPno] = useState(user?.phone_number || "");

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      if (file.size <= 5 * 1024 * 1024) {
        setSelectedFile(file);
      } else {
        toast.error(t("dialogue.5mbimg"));
      }
    } else {
      toast.error(t("dialogue.pngimg"));
    }
  };

  const [loading, setLoading] = useState(false);

  const formatPhoneNumber = (input) => {
    // Remove all non-numeric characters
    if (input) {
      input = input.replace(/\D/g, "");

      if (input.length > 0) {
        input = "(" + input;
      }
      if (input.length > 4) {
        input = input.slice(0, 4) + ") " + input.slice(4);
      }
      if (input.length > 9) {
        input = input.slice(0, 9) + "-" + input.slice(9);
      }
      if (input.length > 14) {
        input = input.slice(0, 14); // Limit to (123) 456-7890 format
      }
    }
    return input;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const textregex = /^(?!.*\d)[a-zA-Z]{3,}$/;
    if (!textregex.test(fname)) {
      console.error("text doesn't meet the criteria.");
      return;
    }

    const lnameregex = /^(?!.*\d)[a-zA-Z]{3,}$/;
    if (!lnameregex.test(lname)) {
      console.error("text doesn't meet the criteria.");
      return;
    }
    // const usPhoneRegex =
    //   /^\+?1?[-.●]?([0-9]{3})[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;

    // if (!usPhoneRegex.test(pno)) {
    //   console.error("text doesn't meet the criteria.");
    //   return;
    // }

    if (pno.replace(/\D/g, "").length < 10) {
      toast.error("Invalid phone number");
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      console.error("text doesn't meet the criteria.");
      return;
    }
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      "Origingosigning",
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", ` Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("email", Cookie.get("user_email"));
    form_data.append("photo", selectedFile);
    form_data.append("first_name", fname);
    form_data.append("last_name", lname);
    form_data.append("phone_number", pno.replace(/\D/g, ""));
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
    };
    fetch(`${apiEndpoint}/profile/update/info`, requestOptions)
      .then((response) => {
        if (response.status == 201) {
          return response.json();
        }
        return response.json();
      })
      .then((result) => {
        if (result && result.data) {
          dispatch(updateUser(result.data.user));
          toast.success(t("dialogue.account_updated"));
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <Toaster />
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={3} sx={{ display: { xs: "none", lg: "block" } }}>
            <SettingSidebar />
          </Grid>
          <Grid item xs={12} lg={9}>
            <Typography
              sx={{
                fontSize: "32px",
                fontWeight: 800,
                color: "black",
                visibility: "hidden",
              }}
            >
              Settings
            </Typography>
            <Grid
              item
              xs={11}
              sx={{
                mx: "auto",
                px: { lg: 7, xs: 2 },
                py: 8,
                boxShadow: 1,
                borderRadius: 2,
              }}
            >
              <Typography
                sx={{
                  fontSize: { lg: "32px", xs: "18px" },
                  fontWeight: 800,
                  color: "#545454",
                }}
              >
                {t("account.title")}
              </Typography>
              <form onSubmit={handleSubmit}>
                <Container>
                  {loading && ( // Conditional rendering of loader
                    <Box
                      sx={{
                        zIndex: 5,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        backgroundColor: "transparent",
                        transform: "translate(-50%, -50%)",
                        top: "50%",
                        left: "50%",
                        opacity: 0.8,
                        width: { lg: "100%", xs: "100%" },
                        height: { lg: "100%", xs: "100%" },
                      }}
                    >
                      <CircularProgress sx={{ color: "#F26F21" }} size={80} />
                    </Box>
                  )}
                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      my: 2,
                      flexWrap: "wrap",
                      justifyContent: { xs: "center", lg: "left" },
                    }}
                  >
                    <Grid
                      xs={4}
                      lg={3}
                      sx={{
                        justifyContent: { xs: "center", lg: "left" },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {selectedFile && (
                        <div
                          style={{
                            marginTop: 20,
                            width: 120,
                            height: 120,
                            borderRadius: "50%",
                            overflow: "hidden",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "#f0f0f0",
                          }}
                        >
                          {/* <img
                            // src={`${backendpoint}${Cookie.get(
                            //   "profile_image_url"
                            // )}`}
                           
                          /> */}
                          <img
                            src={`${backendpoint}${user?.profile_image_url}`}
                            style={{ marginTop: 20, maxWidth: "100%" }}
                          />
                        </div>
                      )}
                    </Grid>
                    <Grid
                      sx={{
                        justifyContent: { xs: "center", lg: "left" },
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <label htmlFor="upload-button">
                        <input
                          type="file"
                          id="upload-button"
                          style={{ display: "none" }}
                          accept="image/jpg, image/jpeg"
                          onChange={handleFileChange}
                        />
                        <Button
                          variant="outlined"
                          component="span"
                          sx={{
                            py: "2px",
                            fontSize: { lg: "16px", xs: "12px" },
                            color: "#F26F21",
                            borderColor: "#F26F21",
                            textTransform: "none",
                            mt: 4,
                            width: { lg: "146px", xs: "119px" },
                            height: { lg: "52px", xs: "41px" },
                            borderRadius: 2,
                          }}
                        >
                          {t("account.uploadbtn")}
                        </Button>
                      </label>
                      <Typography
                        sx={{ color: "#6D6D6D", fontSize: "14px", my: 2 }}
                      >
                        {t("account.imgsize")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Container>
                <Grid container columnSpacing={{ xs: 1, sm: 1, md: 2 }}>
                  <Grid item xs={6}>
                    <CustomField
                      label={t("form.fname")}
                      placeholder="Tom"
                      type="text"
                      id="first_name"
                      name="first_name"
                      value={fname}
                      onChange={(value) => setFname(value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomField
                      label={t("form.lname")}
                      id="last_name"
                      name="last_name"
                      placeholder="Cruise"
                      type="text"
                      value={lname}
                      onChange={(value) => setLname(value)}
                    />
                  </Grid>
                </Grid>
                <CustomField
                  label={t("form.email")}
                  placeholder="abc132@email.com"
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(value) => setEmail(value)}
                  InputProps={{ readOnly: true }}
                />
                <CustomField
                  label={t("form.pno")}
                  placeholder={t("form.pnoplaceholder")}
                  type="tel"
                  id="pno"
                  name="pno"
                  value={formatPhoneNumber(pno)}
                  onChange={(inputValue) => {
                    inputValue = formatPhoneNumber(inputValue);
                    setPno(inputValue);
                  }}
                />
                <Grid item xs={4} lg={2} sx={{ float: "right" }}>
                  <Buttons
                    btnname="button.save"
                    type="submit"
                    variant="contained"
                    className="Orangebtn"
                    sx={{
                      py: "2px",
                      px: 4,
                      fontSize: { lg: "18px", xs: "12px" },
                      textTransform: "none",
                    }}
                  />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default Setting;
