import {
  Container,
  Grid,
  Typography,
  Box,
  TextField,
  FormControl,
  OutlinedInput,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../Components/Button";
import PopoverHover from "../../Components/Popover";
import TimezoneSelect from "react-timezone-select";
import CheckboxLabel from "../../Components/Checkbox";
import SetTimePicker from "../../Components/TimePicker";
import SimpleCheckbox from "../../Components/SimpleCheckBox";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import Cookie from "js-cookie";
import { toast } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import {
  updateStepper3,
  updateTimeZones,
} from "../../Redux/Slices/preferenceSlice";
import dayjs from "dayjs";
import { getRequest, postRequest } from "../../Requests/Requests";
import { use } from "i18next";
import CustomSelect from "../../Components/CustomSelect";
import { setSteppers } from "../../Redux/Slices/stepperInfoSlice";

const apiEndpoint = process.env.REACT_APP_GOSIGNING_API_ENDPOINT;
const StepperStep3 = ({
  onBack,
  onNext,
  lg = 7,
  show,
  // formData,
  // onFormChange,
  setdefault_invitaion_settings,
}) => {
  const [t, i18n] = useTranslation("global");
  const [blocked, setblocked] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleInputClick = (event) => {
    setblocked(true);
    if (
      event.target.value === "9 : 00 am" ||
      event.target.value === "5 : 00 pm"
    ) {
    }
  };
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [selectedTimeZone, setSelectedTimeZone] = useState("");

  const dispatch = useDispatch();
  const reduxFormData = useSelector((state) => state.preferences.stepper3);
  const [timeZoneError, setTimeZoneError] = useState(null);
  // const [timeZones, setTimeZones] = useState([]);
  const timeZones = useSelector((state) => state.preferences.timeZones);
  const [formData, setFormData] = useState(
    reduxFormData ?? {
      default: true,
      checkbox: null,
      start_time: null,
      end_time: null,
      checkedDays: null,
      time_zone: null,
    }
  );

  const onFormChange = (formKey, fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const [stepper3Completed, setStepper3Completed] = useState(false);
  const user = useSelector((state) => state.user?.user);
  const stepperInfo = useSelector((state) => state.stepperInfo.steppers);

  useEffect(() => {
    // if (true) {
    if (stepperInfo?.stepper_3 && !reduxFormData) {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append(
        `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
        `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
      );
      myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
      const form_data = new FormData();
      form_data.append("email", user?.email);
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: form_data,
        redirect: "follow",
      };
      fetch(`${apiEndpoint}/preferences/get/stepper3`, requestOptions)
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else if (response.status == 401) {
            setLoading(false);
          }
        })
        .then((result) => {
          if (result.error) {
            setLoading(false);
          } else {
            setLoading(false);
            if (result?.data?.time_zone) {
              setFormData(result?.data);
              dispatch(updateStepper3({ ...result?.data }));
              setStepper3Completed(true);
              setIsAppointmentClose(result?.data.default);
              dispatch(setSteppers(result?.stepperInfo));
              if (!result?.data?.default) {
                const newStartTimes = new Array(7).fill(null);
                const newcheckedDays = new Array(7).fill(false);
                const newEndTimes = new Array(7).fill(null);

                result?.data?.start_time.forEach((time, index) => {
                  if (time) {
                    newStartTimes[index] = dayjs(time, "HH:mm:ss");
                    newcheckedDays[index] = true;
                  }
                });

                result?.data?.end_time.forEach((time, index) => {
                  if (time) {
                    newEndTimes[index] = dayjs(time, "HH:mm:ss");
                  }
                });

                setStartTimes(newStartTimes);
                setEndTimes(newEndTimes);
                setCheckedDays(newcheckedDays);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    } else {
      if (!formData?.default) {
        const newStartTimes = new Array(7).fill(null);
        const newcheckedDays = new Array(7).fill(false);
        const newEndTimes = new Array(7).fill(null);

        formData?.start_time.forEach((time, index) => {
          if (time) {
            newStartTimes[index] = dayjs(time, "HH:mm:ss");
            newcheckedDays[index] = true;
          }
        });

        formData?.end_time.forEach((time, index) => {
          if (time) {
            newEndTimes[index] = dayjs(time, "HH:mm:ss");
          }
        });

        setStartTimes(newStartTimes);
        setEndTimes(newEndTimes);
        setCheckedDays(newcheckedDays);
      }
    }
  }, []);

  useEffect(() => {
    if (!timeZones) {
      const formData = new FormData();
      formData.append("email", user?.email);
      postRequest("/get/timezones", formData).then((data) => {
        // setTimeZones(data);
        dispatch(updateTimeZones(data));
      });
    }
  }, []);

  const handleChange = (timezone) => {
    setSelectedTimeZone(timezone);
  };

  const [isAppointmentClose, setIsAppointmentClose] = useState(
    formData?.default
  );

  const [startTimes, setStartTimes] = useState(
    formData.start_time
      ? formData.start_time
      : new Array(days.length).fill(null)
  );
  const [endTimes, setEndTimes] = useState(
    formData?.end_time ? formData?.end_time : new Array(days.length).fill(null)
  );

  const handleAppointmentCloseChange = (event) => {
    setblocked(false);

    setdefault_invitaion_settings(event.target.checked);
    setIsAppointmentClose(event.target.checked);
    if (event.target.checked) {
      const startTimes = days.map((day, index) =>
        index < 5 ? dayjs().hour(9).minute(0) : null
      );
      const endTimes = days.map((day, index) =>
        index < 5 ? dayjs().hour(17).minute(0) : null
      );
      onFormChange("stepper3", "default", event.target.checked);

      setStartTimes(startTimes);
      setEndTimes(endTimes);
    } else {
      setCheckedDays(new Array(days.length).fill(false));
      setEndTimes(new Array(days.length).fill(null));
      setStartTimes(new Array(days.length).fill(null));

      setFormData({
        ...formData,
        start_time: new Array(days.length).fill(null),
        end_time: new Array(days.length).fill(null),
        checkedDays: new Array(days.length).fill(false),
        default: false,
      });
    }
  };
  const [checkedDays, setCheckedDays] = useState(
    new Array(days.length).fill(false)
  );

  // Function to handle checkbox change event
  const handleCheckboxChange = (index) => {
    const updatedCheckedDays = [...checkedDays];
    updatedCheckedDays[index] = !updatedCheckedDays[index];
    setCheckedDays(updatedCheckedDays);
    setFormData({
      ...formData,
      checkedDays: updatedCheckedDays,
    });
  };
  const handleStartTimeChange = (index, newStartTime) => {
    const updatedStartTimes = [...startTimes];
    updatedStartTimes[index] = newStartTime;
    setStartTimes(updatedStartTimes);
    setFormData({
      ...formData,
      start_time: updatedStartTimes,
    });
  };

  const handleEndTimeChange = (index, newEndTime) => {
    const updatedEndTimes = [...endTimes];
    updatedEndTimes[index] = newEndTime;
    setEndTimes(updatedEndTimes);
    setFormData({
      ...formData,
      end_time: updatedEndTimes,
    });
  };

  const handleNext = () => {
    if (!formData.time_zone) {
      setTimeZoneError("time zone is required");
      return;
    }
    setTimeZoneError(null);
    let error = false;
    if (
      formData.default == false &&
      formData?.checkedDays?.filter((item) => item == true).length == 0
    ) {
      return;
    } else if (
      formData.default == false &&
      formData?.checkedDays?.filter((item) => item == true).length > 0
    ) {
      formData?.checkedDays?.forEach((item, index) => {
        if (item) {
          if (!startTimes[index] || !endTimes[index]) {
            error = true;
            return;
          }
        }
      });
    }

    if (error) return;
    setLoading(true);
    onFormChange("stepper3", "startTimes", startTimes);
    onFormChange("stepper3", "endTimes", endTimes);
    const form_data = new FormData();
    form_data.append(
      "data",
      JSON.stringify({
        ...formData,
        start_time: startTimes,
        end_time: endTimes,
      })
    );
    form_data.append("email", user?.email);

    postRequest("/preferences/add/stepper3", form_data)
      .then((result) => {
        setLoading(false);
        if (result) {
          if (result?.error) {
            toast.error(result.error);
          } else {
            dispatch(updateStepper3({ ...result?.data }));
            dispatch(setSteppers(result?.stepperInfo));
            setTimeZoneError(null);
            onNext();
          }
        }
      })
      .catch((error) => {
        toast.error("some error occured! refresh the page and try again!");
        console.error(error);
        toast.error("error occured!");
        setLoading(false);
      });
  };

  const handleupdate = () => {
    if (!formData.time_zone) {
      setTimeZoneError("time zone is required");
      return;
    }
    let error = false;
    if (formData.default == false && formData?.checkedDays) {
      if (formData?.checkedDays?.filter((item) => item == true).length == 0) {
        return;
      }
    } else if (
      formData.default == false &&
      formData?.checkedDays?.filter((item) => item == true).length > 0
    ) {
      formData?.checkedDays?.forEach((item, index) => {
        if (item) {
          if (!startTimes[index] || !endTimes[index]) {
            error = true;
            return;
          }
        }
      });
    }

    if (error) return;
    setLoading(true);
    const myHeaders = new Headers();
    myHeaders.append(
      `${process.env.REACT_APP_GOSIGNING_ORIGIN_FIELD}`,
      `Gosigning ${process.env.REACT_APP_GOSIGNING_ORIGIN_TOKEN}`
    );
    myHeaders.append("Authorization", `Bearer ${Cookie.get("access_token")}`);
    const form_data = new FormData();
    form_data.append("data", JSON.stringify(formData));
    form_data.append("email", user?.email);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: form_data,
      redirect: "follow",
    };
    fetch(`${apiEndpoint}/preferences/update/stepper3`, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else if (response.status == 401) {
          setLoading(false);
        }
      })
      .then((result) => {
        // console.log(result);
        if (result.error) {
          toast.error(result.error);
          setLoading(false);
        } else {
          setLoading(false);
          if (result?.data) {
            setFormData(result.data);
            dispatch(updateStepper3({ ...result?.data }));
            toast.success("Updated");
          }
        }
      })
      .catch((error) => {
        toast.error("some error occured! refresh the page and try again!");
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <Container sx={{ position: "relative" }}>
      {loading && ( // Conditional rendering of loader
        <Box
          sx={{
            zIndex: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            backgroundColor: "transparent",
            transform: "translate(-50%, -50%)",
            top: "50%",
            left: "50%",
            opacity: 0.8,
            width: { lg: "100%", xs: "100%" },
            height: { lg: "100%", xs: "100%" },
          }}
        >
          <CircularProgress sx={{ color: "#F26F21" }} size={80} />
        </Box>
      )}
      <Typography
        sx={{
          mx: { lg: "auto", xs: 3 },
          textAlign: "center",
          fontSize: { lg: "40px", xs: "18px" },
          fontWeight: { lg: 700, xs: 600 },
        }}
      >
        {show && <>{t("step3.info")}</>}
      </Typography>

      <Grid container>
        <Grid
          item
          lg={lg}
          xs={12}
          sx={{
            backgroundColor: "white",
            mx: "auto",
            my: 2,
            p: { lg: 3, xs: 2 },
            borderRadius: "8px",
          }}
        >
          <form>
            <Typography
              sx={{
                mx: { lg: "auto", xs: 3 },
                fontSize: { lg: "25px", xs: "14px" },
                fontWeight: { lg: 600, xs: 500 },
                display: "flex",
              }}
            >
              {t("step3.workinghours")}
              <PopoverHover text="step3.workinghours_popover" />
            </Typography>
            {/* <TimezoneInput /> */}
            <div>
              <h3>Select a Time Zone</h3>
              <CustomSelect
                menuItems={timeZones ?? []}
                onChange={(e) => {
                  try {
                    onFormChange(
                      "stepper3",
                      "time_zone",
                      timeZones?.filter(
                        (item) => item.value == e.target.value
                      )[0]?.id
                    );
                  } catch (e) {}
                }}
                value={
                  timeZones
                    ? timeZones?.filter(
                        (item) => item.id == formData?.time_zone
                      )[0]?.value
                    : ""
                }
              ></CustomSelect>
              {selectedTimeZone && (
                <p>Selected Time Zone: {selectedTimeZone.value}</p>
              )}
              {timeZoneError && (
                <Typography sx={{ color: "red" }}>{timeZoneError}</Typography>
              )}
            </div>

            <FormControl component="fieldset" sx={{ px: 0 }}>
              <label>
                <Checkbox
                  checked={isAppointmentClose}
                  onChange={handleAppointmentCloseChange}
                  name="default"
                  id="default"
                  // checked={formData?.default}
                  sx={{
                    color: "#F6B980",
                    pl: 0,
                    "&.Mui-checked": {
                      color: "#F6B980",
                    },
                  }}
                />
                {t("step3.default_setting")}
              </label>
            </FormControl>

            {blocked && (
              <>
                <Typography color="error">First Uncheck a checkbox</Typography>
              </>
            )}

            {isAppointmentClose ? (
              <Grid className="default">
                <Grid
                  container
                  gap={1}
                  alignItems="center"
                  sx={{ my: 2, pl: { lg: 5, xs: 1 } }}
                >
                  {/* <Grid item xs={0} lg={1}> </Grid> */}
                  <Grid
                    item
                    xs={3.2}
                    lg={3.6}
                    sx={{
                      backgroundColor: "#FADCC1",
                      borderRadius: "11px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      px: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", sx: "12px" },
                        textAlign: "center",
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      {t("step3.day")}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    lg={3.9}
                    sx={{
                      backgroundColor: "#FADCC1",
                      borderRadius: "11px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", sx: "12px" },
                        textAlign: "center",
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      {t("step3.start_time")}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    lg={3.9}
                    sx={{
                      backgroundColor: "#FADCC1",
                      borderRadius: "11px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      px: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", sx: "12px" },
                        textAlign: "center",
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      {t("step3.end_time")}
                    </Typography>
                  </Grid>
                </Grid>
                <div>
                  {days.map((day, index) => (
                    <div key={index}>
                      <Grid sx={{ display: "flex" }}>
                        <Grid sx={{ my: 3, mx: { lg: 4, xs: 2 } }}>
                          <SimpleCheckbox
                            checked={index < days.length - 2}
                            disabled={!index < days.length - 2}
                            name={`checkbox-${index}`}
                            // onChange={() => handleCheckboxChange(index)}
                          />
                        </Grid>

                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          sx={{ my: 2 }}
                        >
                          {/* Grid items */}
                          <Grid
                            item
                            xs={9.5}
                            lg={3.6}
                            sx={{
                              backgroundColor: "#D6EBCC",
                              borderRadius: "11px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                              px: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: { lg: "20px", sx: "12px" },
                                textAlign: "center",
                                alignItems: "center",
                                py: 1,
                              }}
                              disabled={index >= days.length - 2}
                            >
                              {t(day)}
                            </Typography>
                          </Grid>
                          <Grid item xs={5} lg={3.6}>
                            <FormControl sx={{}}>
                              <OutlinedInput
                                onClick={handleInputClick}
                                disabled={index >= days.length - 2}
                                value="9 : 00 am"
                                sx={{}}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={5} lg={3.6}>
                            <FormControl sx={{}}>
                              <OutlinedInput
                                onClick={handleInputClick}
                                disabled={index >= days.length - 2}
                                value="5 : 00 pm"
                                sx={{}}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </div>
              </Grid>
            ) : (
              <Grid className="user">
                <Grid
                  container
                  gap={1}
                  alignItems="center"
                  sx={{ my: 2, pl: { lg: 5, xs: 1 } }}
                >
                  {/* <Grid item xs={0} lg={1}> </Grid> */}
                  <Grid
                    item
                    xs={3.2}
                    lg={3.6}
                    sx={{
                      backgroundColor: "#FADCC1",
                      borderRadius: "11px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      px: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", sx: "12px" },
                        textAlign: "center",
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      {t("step3.day")}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    lg={3.9}
                    sx={{
                      backgroundColor: "#FADCC1",
                      borderRadius: "11px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", sx: "12px" },
                        textAlign: "center",
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      {t("step3.start_time")}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={4}
                    lg={3.9}
                    sx={{
                      backgroundColor: "#FADCC1",
                      borderRadius: "11px",
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                      px: 1,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { lg: "20px", sx: "12px" },
                        textAlign: "center",
                        alignItems: "center",
                        py: 1,
                      }}
                    >
                      {t("step3.end_time")}
                    </Typography>
                  </Grid>
                </Grid>
                <div>
                  {days.map((day, index) => (
                    <div key={index}>
                      <Grid sx={{ display: "flex" }}>
                        <Grid sx={{ my: 3, mx: { lg: 4, xs: 2 } }}>
                          <SimpleCheckbox
                            checked={checkedDays[index]}
                            onChange={() => handleCheckboxChange(index)}
                            name={`checkbox-${index}`}
                          />
                        </Grid>

                        <Grid
                          container
                          spacing={1}
                          alignItems="center"
                          sx={{ my: 2, opacity: checkedDays[index] ? 1 : 0.5 }}
                        >
                          {/* Grid items */}
                          <Grid
                            item
                            xs={9.5}
                            lg={3.6}
                            sx={{
                              backgroundColor: "#D6EBCC",
                              borderRadius: "11px",
                              alignItems: "center",
                              display: "flex",
                              justifyContent: "center",
                              px: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: { lg: "20px", sx: "12px" },
                                textAlign: "center",
                                alignItems: "center",
                                py: 1,
                              }}
                            >
                              {t(day)}
                            </Typography>
                          </Grid>
                          {checkedDays[index] ? (
                            <>
                              <Grid item xs={5} lg={3.6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    value={startTimes[index]}
                                    name={`start_time-${index}`}
                                    onChange={(newStartTime) =>
                                      handleStartTimeChange(index, newStartTime)
                                    }
                                    views={["hours", "minutes"]}
                                    sx={{ borderRadius: "12px" }}
                                  />
                                  {checkedDays[index] &&
                                    startTimes[index] == null && (
                                      <Typography
                                        sx={{ color: "red", fontSize: "10px" }}
                                      >
                                        required*
                                      </Typography>
                                    )}
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={5} lg={3.6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    value={endTimes[index]}
                                    name={`end_time-${index}`}
                                    onChange={(newEndTime) =>
                                      handleEndTimeChange(index, newEndTime)
                                    }
                                    views={["hours", "minutes"]}
                                    sx={{ borderRadius: "12px" }}
                                    minTime={
                                      startTimes[index]
                                        ? startTimes[index]
                                        : undefined
                                    }
                                  />
                                </LocalizationProvider>
                                {checkedDays[index] &&
                                  endTimes[index] == null && (
                                    <Typography
                                      sx={{ color: "red", fontSize: "10px" }}
                                    >
                                      required*
                                    </Typography>
                                  )}
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={5} lg={3.6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    views={["hours", "minutes", "seconds"]}
                                    sx={{ borderRadius: "12px" }}
                                    disabled
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={5} lg={3.6}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled
                                    views={["hours", "minutes", "seconds"]}
                                    sx={{ borderRadius: "12px" }}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  ))}
                </div>
              </Grid>
            )}

            {/* //buttons */}
            <Box
              sx={{
                display: "flex",
                pt: 2,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                btnname="button.back"
                type="button"
                onClick={onBack}
                variant="contained"
                className="greybtn"
                sx={{
                  px: { lg: 7, xs: 3 },
                  py: 1.2,
                  fontSize: { lg: "16px", xs: "12px" },
                  mx: 1,
                  borderRadius: "8px",
                }}
              />
              {/* {formData?.time_zone && stepper3Completed ? ( */}
              {stepperInfo?.stepper_3 ? (
                <>
                  <Button
                    btnname="udpate"
                    onClick={handleupdate}
                    type="button"
                    variant="contained"
                    className="Orangebtn"
                    sx={{
                      px: { lg: 7, xs: 3 },
                      py: 1.2,
                      fontSize: { lg: "16px", xs: "12px" },
                      mx: 1,
                      borderRadius: "8px",
                    }}
                  />
                  <Button
                    btnname="button.next"
                    onClick={() => onNext()}
                    type="button"
                    variant="contained"
                    className="Orangebtn"
                    sx={{
                      px: { lg: 7, xs: 3 },
                      py: 1.2,
                      fontSize: { lg: "16px", xs: "12px" },
                      mx: 1,
                      borderRadius: "8px",
                    }}
                  />
                </>
              ) : (
                <Button
                  btnname="button.next"
                  onClick={handleNext}
                  type="button"
                  variant="contained"
                  className="Orangebtn"
                  sx={{
                    px: { lg: 7, xs: 3 },
                    py: 1.2,
                    fontSize: { lg: "16px", xs: "12px" },
                    mx: 1,
                    borderRadius: "8px",
                  }}
                />
              )}
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default StepperStep3;
