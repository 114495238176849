import React from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent } from '@mui/lab';

const TraditionalMethod = ({ number, Icon, Heading, Text }) => {
  return (
    <Grid container spacing={4} sx={{ paddingLeft: 0 }}> {/* paddingLeft set to 0 for container */}
      {/* Timeline Section */}
      <Grid item xs={12} md={4} sx={{ paddingLeft: 0 }}> {/* paddingLeft set to 0 for Grid */}
        <Timeline position="alternate">
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  bgcolor: '#6a9b41',
                  width: { xs: '35px', md: '45px' }, // Responsive size
                  height: { xs: '35px', md: '45px' }, // Responsive size
                  boxShadow: 0,
                  color: 'white',
                  fontSize: { xs: '24px', md: '32px' }, // Responsive font size
                  fontWeight: '600',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginLeft: 0, // Align the circle to the left
                }}
              >
                {number}
              </TimelineDot>
              <TimelineConnector
                sx={{
                  backgroundColor: '#6a9b41',
                  height: { xs: '150px', md: '225px' }, // Responsive height
                  width: '2px',
                }}
              />
            </TimelineSeparator>
            <TimelineContent sx={{ paddingLeft: 0, width: 'auto' }}> {/* paddingLeft set to 0 for TimelineContent */}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                sx={{
                  width: { xs: '230px', md: '300px' }, // Responsive width
                  
                }}
              >
                <Box
                  component="img"
                  src={Icon}
                  alt={Icon}
                  sx={{
                    width: 'auto',
                    height: { xs: '70px', md: '90px' }, // Responsive icon height
                    mb: 2,
                    padding: '0px',
                  }}
                />
                <Typography
                  sx={{
                    fontSize: { lg: '20px', xs: '16px' }, // Responsive font size
                    fontWeight: '600',
                    color: 'black',
                  }}
                >
                  {Heading}
                </Typography>
                <Typography
                  sx={{
                    fontSize: { lg: '18px', xs: '14px' }, // Adjust text size for smaller screens
                    fontWeight: '200',
                    color: 'black',
                    width: {xs: '180px'}
                  }}
                >
                  {Text}
                </Typography>
              </Box>
            </TimelineContent>
          </TimelineItem>
        </Timeline>
      </Grid>
    </Grid>
  );
};

export default TraditionalMethod;
